import React, { useEffect, useState } from 'react'
import api from '../services/api'

import { getClientId } from '../services/auth'

export default function EstoqueNumeros () {
  const [itemsBar, setItemsBar] = useState([])
  useEffect(() => {
    onLoad()
    // eslint-disable-next-line
    },[])

  function onLoad () {
    carregaEstoque()
  }

  async function carregaEstoque () {
    const getBar = await api.get('/bar/products/' + getClientId())
    setItemsBar(getBar.data)
  }

  async function handleSubmit (e) {
    e.preventDefault()
    const arrInput = document.getElementsByClassName('campos')
    const formData = []
    for (const f of arrInput) {
      const estoque = f.querySelector("#estoque");
      const unidade = f.querySelector("#unidade");
      formData.push({
        id: f.id.toString(),
        estoque: parseInt(estoque.value),
        unit: unidade.value,
      });
    }
    try {
      // await api.post("bar/estoqueTotal", formData)
    } catch (e) {
      console.log(e)
    }

    // alert("Estoque atualizado com sucesso")
    // window.location.reload()
  }

  return (
        <>
            <h1>Produtos em Estoque</h1>
            <form onSubmit={handleSubmit}>
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Quantidade em Estoque</th>
                    <th scope="col">Unidade de Medida</th>
                    </tr>
                </thead>
                <tbody>
                    { itemsBar.map((b) => {
                      return (
                        <tr key={b.id} className="campos" id={b.id} >
                        <td>{b.title}</td>
                        <td>
                            <input id="estoque" className="form form-control" type="number" defaultValue={b.estoque}/>
                        </td>
                        <td><input id="unidade" className="form form-control" type="text" defaultValue={b.unit}/></td>
                        </tr>
                      )
                    }) }

                </tbody>
                </table>
                <button className='btn btn-warning'>
                        Atualizar
                    </button>
            </form>
        </>
  )
}
