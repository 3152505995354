import api from './api'

export async function getHistoryLogs() {
  try {
    const res = await api.get('/logs')
    return res.data
  } catch (e) {
    console.log(e)
    return false
  }
}
