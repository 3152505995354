import React from 'react'
import Form from 'react-bootstrap/Form'
import PaymentType from '../Caixa/PaymentType'
import InputMask from 'react-input-mask'

export default function BasicFields({
  doc,
  setDoc,
  gringo,
  setGringo,
  tel,
  setTel,
  tag,
  setTag,
  bd,
  setBd,
  entry,
  setEntry,
  credit,
  setCredit,
  name,
  setName,
  email,
  setEmail,
  payment,
  setPayment,
  notPayment
}) {
  const renderTextField = (label, id, value, setValue, type, validation) => {
    if (typeof setValue !== 'function') {
      return null // Não renderiza nada se setValue não for uma função
    }
    return (
      <div>
        <Form.Label className="mt-4"> {label} </Form.Label>
        <input
          type={type}
          id={id}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={validation || gringo ? 'is-valid form-control' : 'is-invalid form-control'}
        />
      </div>
    )
  }

  function validate11(text) {
    return text.length == 11
  }

  function greatherOrEqualThan(value, min) {
    return value >= min
  }

  return (
    <div>
      {typeof setGringo === 'function' && (
        <p style={{ display: 'flex', alignItems: 'center' }}>
          Documento Estrangeiro -{' '}
          <Form.Check type="checkbox" onChange={() => setGringo(!gringo)} style={{ marginLeft: '5px' }} />
        </p>
      )}
      {renderTextField('CPF/Documento', 'doc', doc, setDoc, 'number', validate11(doc))}

      {renderTextField('Telefone', 'tel', tel, setTel, 'number', validate11(tel))}
      {renderTextField('Nome', 'name', name, setName, 'text', validate11(tel))}
      {typeof setBd === 'function' && (
        <>
          <Form.Label className="mt-4"> Data de Nascimento </Form.Label>
          <InputMask
            mask="99/99/9999"
            value={bd}
            onChange={(e) => setBd(e.target.value)}
            className={'is-valid form-control'}
          />
        </>
      )}
      {renderTextField('Email', 'email', email, setEmail, 'email', true)}
      {renderTextField('Comanda', 'tag', tag, setTag, 'number', greatherOrEqualThan(tag, 3))}
      {renderTextField('Entrada', 'entry', entry, setEntry, 'number', greatherOrEqualThan(tag, 0))}
      {renderTextField('Creditos', 'credit', credit, setCredit, 'number', greatherOrEqualThan(tag, 0))}

      {!notPayment && <PaymentType setPayment={setPayment} payment={payment} name="pagamento" id="" />}
    </div>
  )
}
