import React from 'react'
export function AddingCredits ({
  dinheiro,
  getUserCredits,
  user
}) {
  return <div id='addingCredit'>
          <p>
            Crédito Atual:
            <b style={{
              color: 'red'
            }}>
              {' '}
              R$
              <span id="creditoAtual">{dinheiro(getUserCredits(user.credit))}</span>
            </b>
          </p>
          <a href={`user/${user.id}/credito`} className="btn btn-primary btn-lg" id="btnAdicionarCreditos">Adicionar Créditos</a>
        </div>
}
