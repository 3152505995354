import React, { useState } from 'react'
// import { useAppContext } from "../lib/contextLib";
import Form from 'react-bootstrap/Form'
import LoaderButton from '../components/LoaderButton'
import { roles } from '../helpers/roles'

import api from '../services/api'

import './Usuario.css'

export default function Usuario () {
  const [isLoading, setIsLoading] = useState(false)
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [rg, setRg] = useState('')
  const [cpf, setCpf] = useState('')
  const [birth, setBirth] = useState('')
  const [tel, setTel] = useState('')
  const [senha, setSenha] = useState('')
  const [role, setRole] = useState('Operator')

  function validateForm () {
    return (tel.length > 0 || cpf.length > 0) && nome.length > 0
  }

  async function handleSubmit (event) {
    event.preventDefault()
    setIsLoading(false)
    if (email === '') {
      setEmail(nome + Math.floor(Math.random() * 1000000) + '@kitnet.fake')
    }
    const nomecompleto = nome.split(' ')
    if (nomecompleto.length <= 1) {
      alert('Por favor, corriga o nome')
      setIsLoading(false)
      return null
    }

    const primeiro = nomecompleto.shift()

    const sobrenome = nomecompleto.join(' ')
    try {
      const formData = {
        name: primeiro,
        lastname: sobrenome,
        email,
        rg,
        cpf,
        birth: new Date(birth),
        tel,
        password: senha,
        role
      }

      await api.post('users/admin', formData)
      alert('Usuário criado com sucesso')
      window.location = '/'
    } catch (e) {
      alert('Erro ao criar o cadastro:\n\n' + e.response.data.message)
    }
    setIsLoading(false)
  }

  return (
        <div className = "container">
        <h1> Cadastrar Admin </h1>
        <Form onSubmit = { handleSubmit }>

        <Form.Group size = "lg">
        <Form.Label> Nome completo </Form.Label>
        <Form.Control autoFocus type = "text"
        id="name"
        value = { nome }
        onChange = {
            (e) => setNome(e.target.value) }
        />
<Form.Label
        > Email </Form.Label>
        <Form.Control type = "email"
        value = { email }
        id="email"
        onChange = {
            (e) => setEmail(e.target.value) }
        />

        <Form.Label> Senha </Form.Label>
        <Form.Control type = "password"
        id="password"

        onChange = {
            (e) => setSenha(e.target.value) }
        />

        <Form.Label> RG
        </Form.Label>
        <Form.Control autoFocus type = "text"
        value = { rg }
        maxLength = { 15 }
        id="rg"
        onChange = {
            (e) => setRg(e.target.value) }
        />

        <Form.Label> CPF </Form.Label>
        <Form.Control autoFocus type = "text"
        value = { cpf }
        id="cpf"
        maxLength = { 11 }
        onChange = {
            (e) => setCpf(e.target.value) }
        />

        <Form.Label> Data de Nascimento </Form.Label>
        <Form.Control autoFocus type = "date"
        value = { birth }
        id="nascimento"
        onChange = {
            (e) => setBirth(e.target.value) }
        />

        <Form.Label> Telefone </Form.Label>
        <Form.Control autoFocus type = "text"
        value = { tel }
        id="tel"
        maxLength = { 11 }
        onChange = {
            (e) => setTel(e.target.value) }
        />

        </Form.Group>
        <Form.Label > Função </Form.Label>
        <select id="role" className="form-control" onChange = {(e) => setRole(e.target.value)}>
            <option value="">-----------</option>
            {roles.map((r) => (
                <option key={r} value={r}>{r}</option>
            ))}

        </select>

        <LoaderButton block size = "lg"
        type = "submit"
        className="my-4"
        id="btnCadastrar"
        isLoading = { isLoading }
        disabled = {!validateForm() }>
        Cadastrar
        </LoaderButton>

        </Form>
        </div>
  )
}
