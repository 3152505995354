import React from 'react'
import CaixaComanda from '../components/CaixaComanda'

import CaixaFila from '../components/CaixaFila'
import CaixaAtividades from '../components/CaixaAtividades'
import CaixaSimples from '../components/CaixaSimples'
import { getFeatures } from '../services/auth'

export default function CaixaOperar () {
  return (
    <div>
        <h1>Caixa</h1>

        <CaixaComanda />

        <CaixaSimples />
        <hr />
        { getFeatures().includes('Hostess') && (<CaixaFila />)}

        <hr />
        <CaixaAtividades />
    </div>
  )
}
