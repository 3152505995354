import React from 'react'

import { BsFillTelephoneFill } from 'react-icons/bs'
import moment from 'moment'
import { telefone } from '../../helpers/Formata'
import { getClientId } from '../../services/auth'

export default function UsersQueue({ queue }) {
  return (
    <div>
      {queue.map((u) => {
        const portaria = u.portaria.find((p) => String(p.client.id) === String(getClientId()))

        return (
          <p className="text-center" key={u.id}>
            <a className="m-1 p-1 box" href={`portaria/${u.id}`}>
              <span
                id={u.tel}
                className={`text-decoration-underline ${portaria.obs.toLowerCase().includes('vip') ? 'text-vip' : ''}`}
              >
                {u.name} {u.lastname} - <BsFillTelephoneFill size={20} className="mx-1" /> {telefone(u.tel.slice(-4))} |{' '}
                {moment(new Date(portaria.ts)).format('HH:mm')}
                {portaria.obs.toLowerCase().includes('vip') ? ' - VIP' : ''}
              </span>
            </a>
          </p>
        )
      })}
    </div>
  )
}
