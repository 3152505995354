import React, { useState } from 'react'
import api from '../services/api'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function VendasPorBar () {
  const [today] = useState(new Date())
  const [priorDate] = useState(new Date().setDate(today.getDate() - 1))

  const [inicio, setInicio] = useState(priorDate)
  const [fim, setFim] = useState(today)

  const [vendas, setVendas] = useState([])

  async function getVendasPorBar () {
    const isoInicio = new Date(inicio).toISOString()
    const isoFim = new Date(fim).toISOString()
    const res = await api.get(`/users/r/vendidosPorBar/${isoInicio}/${isoFim}`)
    setVendas(res.data)
  }

  return (
    <div>
      <h1>Vendas por Bar</h1>
      <h3>Período</h3>
            <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Início</label>
                <div className="col-sm-10">
                <DatePicker
                id="inicio"
                    selected={inicio}
                    onChange={(date) => setInicio(date)}
                    timeInputLabel="Horário:"
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className="form-control"
                    showTimeInput
                />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Fim</label>
                <div className="col-sm-10">
                <DatePicker
                id="fim"
                    selected={fim}
                    onChange={(date) => setFim(date)}
                    timeInputLabel="Horário:"
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className="form-control"
                    showTimeInput
                />
                </div>
            </div>
            <button className="btn btn-primary" onClick={getVendasPorBar}>Pesquisar</button>
      <div>
      <table className="table table-striped">

  <thead>
    <tr>
      <th scope="col">Produto</th>
      <th scope="col">Bar</th>
      <th scope="col">Total</th>
    </tr>
  </thead>
  <tbody>
    { vendas.map((v) => (
       <tr key={v._id.product}>
       <th scope="row">{v._id.product}</th>
       <td>{v._id.bar}</td>
       <td>{v.quantidade}</td>
     </tr>
    ))}

  </tbody>
</table>
      </div>
    </div>
  )
}
