import React, { useEffect, useState, useRef } from 'react'
import './Home.css'

import { getName, getRole } from '../services/auth'
import BarLinks from '../roles/BarLinks'
import CaixaLinks from '../roles/CaixaLinks'
import PortariaLinks from '../roles/PortariaLinks'
import GuardaVolumesLinks from '../roles/GuardaVolumesLinks'
import EstoqueLinks from '../roles/EstoqueLinks'
import { Link } from 'react-router-dom'
import Loading from '../components/Loading'
import { getNumberOfPendingActions } from '../services/users'

export default function Home() {
  const loadingRef = useRef(false)
  const [loaded, setLoaded] = useState(false)
  const [nAcoes, setNAcoes] = useState(0)

  useEffect(() => {
    onLoad()

    function onLoad() {
      if (!loadingRef.current) {
        if (getRole() === 'Operator') {
          window.location = '/selecao'
        } else {
          getNumberOfPendingActions().then((e) => setNAcoes(e))
        }
        loadingRef.current = true
      }

      setLoaded(true)
    }
  }, [])

  if (!loaded) {
    return <Loading size={50} />
  }

  return (
    <div className="Home ">
      <p className="text-muted">Bem vindo</p>
      <h3>
        {getName()} - {getRole()}
      </h3>
      <div className="text-center">
        {getRole() === 'Bar' ? (
          <>
            <BarLinks />
          </>
        ) : (
          <p></p>
        )}

        {getRole() === 'Caixa' ? (
          <>
            <CaixaLinks />
          </>
        ) : (
          <p></p>
        )}

        {getRole() === 'BarCaixa' ? (
          <>
            <BarLinks />
            <CaixaLinks />
          </>
        ) : (
          <p></p>
        )}

        {getRole() === 'Admin' ? (
          <div className="">
            <BarLinks />
            <hr />
            <CaixaLinks />
            <hr />
            <PortariaLinks />
            <hr />
            <GuardaVolumesLinks />
            <hr />
            <div className="buttons-container">
              <p>
                <Link className="" to="/caixaAdm">
                  <button className="btn btn-block btn-danger button">
                    <span className="button-font-size">Caixa Admin</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/promocoes">
                  <button className="button btn btn-block btn-secondary ">
                    <span className="button-font-size">Promoções</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/eventos">
                  <button id="btnEventos" className="button btn btn-block btn-warning ">
                    <span className="button-font-size">Eventos</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/usuarios">
                  <button id="btnUsuarios" className="button btn btn-block btn-dark ">
                    <span className="button-font-size">Usuarios</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/acoesPendentes">
                  <button id="btnAcoesPendentes" className="button btn btn-block btn-primary ">
                    <span className="button-font-size">Ações Pendentes ({nAcoes}) </span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/pedidos">
                  <button id="btnVisualizarPedido" className="button btn btn-info btn-block ">
                    <span className="button-font-size">Visualizar Pedidos</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/relatorio">
                  <button id="btnRelatorios" className="btn btn-info btn-block button">
                    <span className="button-font-size">Relatórios</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/vendasPorBar">
                  <button id="btnVendasPorBar" className="button btn btn-warning ">
                    <span className="button-font-size">Vendas Por Bar</span>
                  </button>
                </Link>
              </p>
              <hr />
              <p>
                <Link className="" to="/bloqueados">
                  <button id="btnRelatorios" className="button btn btn-info btn-danger ">
                    <span className="button-font-size">Lista Blockeds</span>
                  </button>
                </Link>
              </p>
              <p>
                {/* <Link className="" to="/logs">
                  <button id="btnRelatorios" className="button btn btn-info btn-secondary ">
                    <span className="button-font-size">Logs Mudanças</span>
                  </button>
                </Link> */}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}

        {getRole() === 'Estoque' ? (
          <>
            <EstoqueLinks />
          </>
        ) : (
          <></>
        )}

        {getRole() === 'Portaria' ? (
          <>
            <PortariaLinks />
          </>
        ) : (
          <></>
        )}
        {getRole() === 'CaixaPortaria' && <GuardaVolumesLinks />}
        {getRole() === 'GuardaVolumes' ? (
          <>
            <GuardaVolumesLinks />
          </>
        ) : (
          <></>
        )}

        {getRole() === 'BarCaixaEstoquePortaria' ? (
          <>
            <BarLinks />
            <CaixaLinks />
            <PortariaLinks />
          </>
        ) : (
          <></>
        )}

        {getRole() === 'CaixaPortaria' ? (
          <>
            <CaixaLinks />
            <PortariaLinks />
          </>
        ) : (
          <></>
        )}

        {getRole() === 'BarPortaria' ? (
          <>
            <BarLinks />
            <PortariaLinks />
          </>
        ) : (
          <></>
        )}

        {getRole() === 'BarCaixaPortaria' ? (
          <>
            <BarLinks />
            <CaixaLinks />
            <PortariaLinks />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
