import React from 'react'
import PropTypes from 'prop-types'

CaixaRelatorioBasico.propTypes = {
  caixa: PropTypes.object
}

export default function CaixaRelatorioBasico ({ caixa }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          Dinheiro em Caixa
        </div>
        <div className="col">
          R$:
          {' '}
          {caixa.valorInicial + caixa.entrada - caixa.saida}
          ,00
        </div>
      </div>
      <div className="row">
        <div className="col">
          Valor em cartão
        </div>
        <div className="col">
          R$:
          {' '}
          {caixa.cartao}
          ,00
        </div>
      </div>
      <div className="row">
        <div className="col">
          Total
        </div>
        <div className="col">
          R$:
          {' '}
          {caixa.cartao + caixa.valorInicial + caixa.entrada - caixa.saida }
          ,00
        </div>
      </div>
    </div>
  )
}
