import React, { useEffect, useState } from 'react'
import api from '../services/api'

import Modal from '../components/Modal'

import Loading from './../components/Loading'

export default function AcoesPendentes () {
  const [acoesPendentes, setAcoesPedentes] = useState([])
  const [loaded, setLoaded] = useState(false)

  const [modal, setModal] = useState({})

  useEffect(() => {
    onLoad().then(() => setLoaded(true))
    
  }, [])

  async function onLoad () {
    try {
      const acoesP = await api.get('users/acoesPendentes')
      setAcoesPedentes(acoesP.data)
    } catch (e) {
      alert('Falha\n' + e.response.data.message)
    }
  }

  async function approveAll () {
    const confirm = window.confirm('tem certeza que quer fazer isso?')
    if (confirm) {
      try {
        setModal({
          show: true,
          type: 'warning',
          title: 'Carregando',
          msg: 'Trabalhando na sua acao'
        })
        await api.post('/users/aceitarTudo', {
          acao: 'aceitar',
          id: 'all'
        })
        setModal({
          show: true,
          type: "success",
          title: "Sucesso",
          msg: "Alteracao concluida",
          link: "/acoesPendentes",
          refresh: true,
        });
      } catch (e) {
        setModal({
          show: true,
          type: "danger",
          title: "Falha",
          msg: "Falha\n" + e.response.data.message,
          link: "/acoesPendentes",
          refresh: true,
        });
      }
    }
  }

  async function envia (acao, id) {
    const confirm = window.confirm('tem certeza que quer fazer isso?')
    if (confirm) {
      try {
        await api.patch('/users/acoesPendentes', {
          acao,
          id
        })
        setModal({
          show: true,
          type: "success",
          title: "Sucesso",
          msg: "Alteracao concluida",
          link: "/acoesPendentes",
          refresh: true,
        });
      } catch (e) {
        setModal({
          show: true,
          type: "danger",
          title: "Falha",
          msg: "Falha\n" + e.response.data.message,
          link: "/acoesPendentes",
          refresh: true,
        });
      }
    }
  }
  return (
        <div>
            <Modal modal={modal} />
            <h1>Açoes pendentes</h1>
            { loaded
              ? (
                <>
                    { acoesPendentes.length
                      ? (
                        <>
                        { acoesPendentes.map((ap) => {
                          return (
                            <div key={ap.id}>
                                <hr />
                                <h4>{ap.name} {ap.lastname } - {ap.tel}</h4>

                                <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Acao</th>
                                    <th scope="col">De</th>
                                    <th scope="col">Para</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                { ap.pendingActions.map((pa) => {
                                  return (
                                        <tr key={pa._id}>
                                            <th scope="row">{pa.action}</th>
                                            <td>{pa.from}</td>
                                            <td>{pa.to}</td>
                                            <td>
                                                <button id="aceitar" className="btn btn-warning" onClick={() => envia('aceitar', pa._id)}>Aceitar</button>
                                            </td>
                                            <td>
                                                <button id="rejeitar" className="btn btn-danger" onClick={() => envia('rejeitar', pa._id)}>Rejeitar</button>
                                            </td>
                                        </tr>
                                  )
                                })}

                                </tbody>
                                </table>

                            </div>
                          )
                        })}

                        <div><button onClick={approveAll}>Aprovar todas</button></div>
                    </>
                        )
                      : (
                        <>Não há ações pendentes</>
                        )}
                </>
                )
              : (
                <Loading size={120} ></Loading>
                )}

        </div>
  )
}
