import api from './api'

export async function getPromotions() {
  try {
    const response = await api.get('promocoes')
    if (response.status === 200) {
      return response.data
    }
  } catch (e) {
    console.log(e.message)
    return []
  }
}
