import React from 'react'
import PropTypes from 'prop-types'
// Verifica se o produto já tá no cache
// Se tiver nao mostra opção
// Se n tiver, mostra opção de adicionar
// Ao clicar já some

import { setFav } from '../services/produtos'

import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

FavoritoProduto.propTypes = {
  produto: PropTypes.object,
  favoritos: PropTypes.array,
  setFavoritos: PropTypes.func
}

export default function FavoritoProduto({ produto, favoritos, setFavoritos }) {
  function addToFavorites(p) {
      const updatedFavorites = [...favoritos, p]
      setFavoritos(updatedFavorites)
      setFav(updatedFavorites)
    
  }

  function removeFromFavorites(p) {
      const updatedFavorites = favoritos.filter((v) => v.id !== p.id)
      setFavoritos(updatedFavorites)
      setFav(updatedFavorites)
    
  }

  function productInFav(p) {
    return !!favoritos.find((v) => v.id === p.id)
  }
  return (
    <div> 
      {productInFav(produto) ? (
        <AiFillStar size={30} onClick={() => removeFromFavorites(produto)} />
      ) : (
        <AiOutlineStar size={30} onClick={() => addToFavorites(produto)} />
      )}
    </div>
  )
}
