import React, { useState, useEffect } from 'react'
import LoaderButton from '../components/LoaderButton'
import api from '../services/api'
import Modal from '../components/Modal'
import Loading from "../components/Loading";

export default function CaixaAbrir({ setIsCaixaOpen }) {
  useEffect(() => {
    getCaixas();
  }, []);

  async function getCaixas() {
    const res = await api.get("/caixa/livres");
    setCaixas(res.data);
    setIsLoaded(true);
  }

  const [caixas, setCaixas] = useState([]);
  const [caixaNumber, setCaixaNumber] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [entrada, setEntrada] = useState(0);
  const [modal, setModal] = useState({});
  const [loaded, setIsLoaded] = useState(false);

  async function handleSubmit() {
    setIsLoading(true);
    setModal({
      show: true,
      type: "warning",
      title: "Abrindo caixa",
      msg: "Aguarde...",
      loading: true,
    });
    try {
      await api.post(`/caixa/${caixaNumber}`, {
        valorInicial: parseInt(entrada),
      });
      console.log(setIsCaixaOpen);

      if (setIsCaixaOpen) {
        setIsCaixaOpen(true);
      } else {
        console.log("Uai");
        setModal({
          show: true,
          type: "success",
          title: "Sucesso",
          msg: "Caixa aberto com sucesso",
          link: "/caixaOperar",
        });
      }
    } catch (e) {
      setModal({
        show: true,
        type: "danger",
        title: "Erro",
        msg: JSON.stringify(e.response.data.message),
        link: "/",
      });
    }
    setIsLoading(false);
  }

  function validateForm() {
    return entrada.length > 0;
  }
  if (!loaded) {
    return <Loading size={70} />;
  }
  return (
    <div>
      <Modal modal={modal} />
      <h1>Abrir Caixa</h1>
      <p>Você não possui um caixa aberto ainda</p>
      <p>Selecione seu caixa</p>
      <select
        id="slcCaixa"
        onChange={(e) => setCaixaNumber(parseInt(e.target.value, 10))}
        className="form-control my-2"
      >
        <option className="form-control" value="">
          --- Selecione ---
        </option>
        {caixas.map((c) => (
          <option key={c.number}>{c.number}</option>
        ))}
      </select>
      <label htmlFor="staticEmail" className="">
        Valor Inicial
      </label>
      <input
        onChange={(e) => setEntrada(e.target.value)}
        className="form-control "
        type="number"
        id="valorInicial"
      />
      <LoaderButton
        isLoading={isLoading}
        disabled={!validateForm()}
        onClick={handleSubmit}
        className="btn btn-primary my-2 w-100"
      >
        Abrir Caixa
      </LoaderButton>
    </div>
  );
}
