import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import api from '../services/api'

import Form from 'react-bootstrap/Form'
import LoaderButton from '../components/LoaderButton'
import { roles } from '../helpers/roles'
import Modal from '../components/Modal'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { getUserRole, getUserComanda, getUserCredits } from '../helpers/Users'
import { getClientId } from '../services/auth'
import ListBlockedReasons from '../components/Users/ListBlockedReasons'

export default function UsuarioEdita() {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [rg, setRg] = useState('')
  const [cpf, setCpf] = useState('')
  const [birth, setBirth] = useState('')
  const [tel, setTel] = useState('')
  const [role, setRole] = useState('Operator')
  const [senha, setSenha] = useState('XXXXXX')
  const [until, setUntil] = useState('')
  const [comanda, setComanda] = useState('')
  const [portaria, setPortaria] = useState(false)
  const [credito, setCredito] = useState('')
  const [blocked, setBlocked] = useState(false)
  const [blockInfinity, setBlockInfinity] = useState(false)
  const [blockedUntil, setBlockedUntil] = useState('')
  const [blockedReason, setBlockedReason] = useState('')
  const [creditUsage, setCreditUsage] = useState([])
  const [creditCharge, setCreditCharge] = useState([])
  const [modal, setModal] = useState({})
  const [user, setUser] = useState({})

  function isUserBlockedToThisClient(user) {
    const x = user.blocked.find(
      (c) =>
        toString(getClientId()) === toString(c.client.id) && c.isBlocked === true && new Date() <= new Date(c.until)
    )
    if (x) {
      return {
        reason: x.reason,
        blocked: true,
        until: x.until
      }
    } else {
      return {
        reason: '',
        blocked: false,
        until: ''
      }
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    const user = await api.get('users/' + params.id)

    if (user.data.name) {
      setUser(user.data)
      setNome(user.data.name + ' ' + user.data.lastname)
      setEmail(user.data.email)
      if (user.data.rg) {
        setRg(user.data.rg)
      }
      if (user.data.cpf) {
        setCpf(user.data.cpf)
      }
      if (user.data.birth) {
        setBirth(new Date(user.data.birth).toISOString().split('T')[0])
      }
      if (user.data.tel) {
        setTel(user.data.tel)
      }

      const b = isUserBlockedToThisClient(user.data)
      setBlocked(b.blocked)
      setBlockedReason(b.reason)
      setBlockedUntil(b.until)

      setCreditUsage(user.data.credit_usage)
      setCreditCharge(user.data.credit_charge)
      setPortaria(user.data.portaria)

      setRole(getUserRole(user.data.client, getClientId()))
      setComanda(getUserComanda(user.data.comanda))
      setCredito(getUserCredits(user.data.credit))
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setIsLoading(false)
    try {
      const nomecompleto = nome.split(' ')
      if (nomecompleto.length <= 1) {
        alert('Por favor, corriga o nome')
        setIsLoading(false)
        return null
      }
      const primeiro = nomecompleto.shift()

      const sobrenome = nomecompleto.join(' ')
      const formData = {
        name: primeiro,
        lastname: sobrenome,
        email,
        rg,
        cpf,
        birth: new Date(birth),
        tel,
        pass: new Date(),
        role,
        password: senha
      }

      await api.post('/users/' + params.id, formData)
      alert('Atualizado com sucesso')
      window.location = '/usuarios'
    } catch (e) {
      alert(e.response.data.message)
    }
  }

  async function state(state) {
    let reason = ''
    if (state === true) {
      reason = window.prompt('Qual o motivo?')
      if (reason === null) {
        alert('Você deve especificar o motivo')
        return false
      }
    }

    if (reason !== '' || state === false) {
      try {
        await api.put('/users/changeState', {
          id: params.id,
          state,
          reason,
          until: until === '' ? new Date('2050') : new Date(until)
        })
        alert('Alterado com sucesso')
        window.location = '/usuario/' + params.id
      } catch (e) {
        alert(e)
      }
    } else {
      alert('O motivo do bloqueio é necessário!')
    }
  }

  async function apagar() {
    const apagar = window.confirm('Certeza que quer apagar?')
    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando apagar o usuário',
      msg: 'Aguarde...'
    })
    if (apagar === true) {
      try {
        await api.delete('users/' + params.id)
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Usuário deletado com sucesso',
          link: '/usuarios'
        })
      } catch (e) {
        setModal({
          show: true,
          type: 'danger',
          title: 'Erro',
          msg: JSON.stringify(e),
          link: '/'
        })
      }
    } else {
      setModal({
        show: false
      })
    }
  }

  function setTimeToInfinity() {
    setBlockInfinity(!blockInfinity)
    console.log(blockInfinity)
  }
  return (
    <div className="container">
      <Modal modal={modal} />
      <h1> Cadastrar Admin </h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg">
          <Form.Label className="mt-1 mb-2"> Nome completo </Form.Label>
          <Form.Control
            id="name"
            autoFocus
            type="text"
            value={nome}
            className={nome.split(' ').length > 1 ? 'is-valid' : 'is-invalid'}
            onChange={(e) => setNome(e.target.value)}
          />

          <Form.Label className="mt-1 mb-2"> Comanda </Form.Label>
          <Form.Control
            id="comanda"
            autoFocus
            type="number"
            value={comanda}
            className={parseInt(comanda, 10) > 0 ? 'is-valid' : 'is-invalid'}
            onChange={(e) => setComanda(e.target.value)}
          />

          <Form.Label className="mt-1 mb-2"> Credito </Form.Label>
          <div className="mt-2 mb-5">
            <strong>{credito}</strong>
          </div>

          <Form.Label id="email" className="mt-1 mb-2">
            {' '}
            Email{' '}
          </Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={email}
            className={email.length > 1 ? 'is-valid' : 'is-invalid'}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Form.Label className="mt-1 mb-2"> Senha </Form.Label>
          <Form.Control id="password" autoFocus type="password" onChange={(e) => setSenha(e.target.value)} />

          <Form.Label className="mt-1 mb-2"> RG</Form.Label>
          <Form.Control
            id="rg"
            autoFocus
            type="text"
            value={rg}
            maxLength={15}
            className={rg.length > 1 ? 'is-valid' : 'is-invalid'}
            onChange={(e) => setRg(e.target.value)}
          />

          <Form.Label className="mt-1 mb-2"> CPF </Form.Label>
          <Form.Control
            id="cpf"
            autoFocus
            type="text"
            value={cpf}
            maxLength={11}
            className={rg.cpf == 11 ? 'is-valid' : 'is-invalid'}
            onChange={(e) => setCpf(e.target.value)}
          />

          <Form.Label className="mt-1 mb-2"> Data de Nascimento </Form.Label>
          <Form.Control
            id="birth"
            autoFocus
            type="date"
            value={birth}
            className={birth.length > 1 ? 'is-valid' : 'is-invalid'}
            onChange={(e) => setBirth(e.target.value)}
          />

          <Form.Label className="mt-1 mb-2"> Telefone </Form.Label>
          <Form.Control
            id="tel"
            autoFocus
            type="text"
            value={tel}
            maxLength={11}
            className={tel.length >= 11 ? 'is-valid' : 'is-invalid'}
            onChange={(e) => setTel(e.target.value)}
          />
          <Form.Label className="mt-2 mb-1"> Função </Form.Label>
          <select id="role" className="form-control" onChange={(e) => setRole(e.target.value)}>
            <option value={role}>{role}</option>
            <option value="">-----------</option>
            {roles.map((r) => (
              <option key={r} value={r}>
                {r}
              </option>
            ))}
            <option value="User">User</option>
          </select>
        </Form.Group>
        <LoaderButton block size="lg" type="submit" className="my-3" isLoading={isLoading}>
          Atualizar
        </LoaderButton>
      </Form>
      {blocked ? (
        <>
          <h3>Bloqueado por:</h3>
          <p className="box border px-2 py-2">{blockedReason}</p>
          <h3>Até {new Date(blockedUntil).toLocaleString('BR')}</h3>
          <LoaderButton
            block
            size="lg"
            type="submit"
            id="btnDesbloquear"
            className=" my-4 btn btn-success"
            onClick={() => {
              state(false)
            }}
          >
            Desbloquear
          </LoaderButton>
        </>
      ) : (
        <>
          <div className="form-group">
            <p htmlFor="inicio">Bloquear Até</p>
            <DatePicker
              id="inicio"
              selected={until}
              onChange={(date) => setUntil(date)}
              timeInputLabel="Horário:"
              dateFormat="dd/MM/yyyy h:mm aa"
              showTimeInput
            />
            <p className="my-3">
              Para sempre <input type="checkbox" name="selecao" value="true" onChange={setTimeToInfinity} />
            </p>
          </div>
          <LoaderButton
            block
            size="lg"
            type="submit"
            id="btnBloquear"
            className=" my-4 btn btn-warning"
            onClick={() => {
              state(true)
            }}
          >
            Bloquear Usuário
          </LoaderButton>
        </>
      )}
      <hr />
      <LoaderButton
        block
        size="lg"
        type="submit"
        id="btnApagarUsuario"
        className=" my-4 btn btn-danger"
        onClick={apagar}
      >
        Apagar
      </LoaderButton>
      <hr />
      Alterar a quantidade de créditos:{' '}
      <a href={`alterarCreditos/${params.id}`} className="btn btn-warning">
        Alterar
      </a>
      <hr />
      <h3>Uso de Créditos</h3>
      <ul>
        {creditUsage.map((x) => (
          <li key={x.created_at}>
            {new Date(x.created_at).toLocaleString('pt-BR')} (R$ {x.value}) | {x.quantity} {x.product} -{' '}
            {x.admin_fullname}
          </li>
        ))}
      </ul>
      <h3>Recargas de crédito</h3>
      <ul>
        {creditCharge.map((x) => (
          <li key={x.created_at}>
            {new Date(x.created_at).toLocaleString('pt-BR')} | ${x.value} - {x.admin_fullname} -
            <a href={'/user/' + params.id + '/' + btoa(x.created_at) + '/desfazer'}>Desfazer</a>
          </li>
        ))}
      </ul>
      {portaria && (
        <div>
          <h3>Portaria</h3>
          <p>
            Coloco na fila da portaria por: <b>{portaria.admin}</b>
          </p>
          <p>Com a observação: {portaria.obs}</p>
          <p>Em {new Date(portaria.ts).toLocaleString('pt-BR')}</p>
        </div>
      )}
      {user.blocked && user.blocked.length && <ListBlockedReasons user={user} />}
    </div>
  )
}
