import React, { useState } from 'react'
import api from '../../services/api'
import Modal from '../Modal'
export function PayingCredits ({
  dinheiro,
  getUserCredits,
  user
}) {
  const [paymentType, setPaymentType] = useState('')
  const [modal, setModal] = useState('')
  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value)
  }

  async function payDebits () {
    const ctz = confirm('Tem certeza?')
    if (ctz) {
      try {
        setModal({
          show: true,
          type: 'warning',
          title: 'Quitando débitos',
          msg: 'Trabalhando em sua operação',
          loading: true
        })
        await api.post(
          'users/debts/settle',
          {
            userId: user.id,
            paymentType
          }
        )
        setModal({
          show: true,
          type: 'success',
          title: 'Débitos quitados',
          msg: 'Operação conclúida com sucesso!',
          link: '/caixaOperar'
        })
      } catch (e) {
        console.log(e)
        alert('Falhamos :(')
      }
    }
  }
  return <div id='addingCredit'>
    <Modal modal={modal} />
          <h1 className='text-center m-5'>
            Total devido
            <b style={{
              color: 'red'
            }}>
              {' '}
              R$
              <span id="creditoAtual">{dinheiro(getUserCredits(user.credit))}</span>
            </b>
          </h1>
          <div className='text-center m-5'>
          <div className='container'>
          <div className="d-flex justify-content-center">
          <div className="form-check form-check-inline form-check-lg">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMethod"
              id="pix"
              value="pix"
              checked={paymentType === 'pix'}
              onChange={handlePaymentTypeChange}
            />
            <label className="form-check-label" htmlFor="pix">
              Pix
            </label>
          </div>
          <div className="form-check form-check-inline form-check-lg">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMethod"
              id="credit"
              value="credit"
              checked={paymentType === 'credit'}
              onChange={handlePaymentTypeChange}
            />
            <label className="form-check-label" htmlFor="cartao">
              Cartao
            </label>
          </div>
          <div className="form-check form-check-inline form-check-lg">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMethod"
              id="cash"
              value="cash"
              checked={paymentType === 'cash'}
              onChange={handlePaymentTypeChange}
            />
            <label className="form-check-label" htmlFor="cash">
              Dinheiro
            </label>
          </div>
        </div>
          </div>
          { getUserCredits(user.credit) < 0 && (
            <button onClick={payDebits} className="my-2 btn btn-warning btn-lg" id="btnAdicionarCreditos">Quitar débitos</button>
          )}

          </div>
        </div>
}
