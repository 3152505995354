import React, { useEffect, useState } from 'react'
import EventoLista from '../components/EventoLista'
import EventoNovo from '../components/EventoNovo'
import api from '../services/api'

export default function Eventos () {
  const [events, setEvents] = useState([])

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad () {
    if (events.length === 0) {
      const ev = await api.get('/events')
      setEvents(ev.data.slice(0, 4))
    }
  }

  return (
        <div>
            <h1>Eventos</h1>
            <EventoNovo />
            <hr/>
            <EventoLista events={events} />
        </div>
  )
}
