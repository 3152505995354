import React from 'react'

import { BsArrowRepeat } from 'react-icons/bs'
import './LoaderButton.css'
import PropTypes from 'prop-types'

Loading.propTypes = {
  size: PropTypes.number
}

export default function Loading ({ size }) {
  return (
    <div className="Load text-center">
      <BsArrowRepeat className="spinning center" size={size} />
    </div>
  )
}
