import React, { useEffect, useState, memo } from 'react'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { BsCreditCard } from 'react-icons/bs'
import api from '../services/api'
import Loading from './Loading'

export default memo(function CaixaSimples() {
  const [caixa, setCaixa] = useState({})
  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    const r = await api.get('caixa/operador')
    setCaixa(r.data)
  }
  async function fecharCaixa() {
    const prompt = window.confirm('Você tem certeza que quer fechar o seu caixa?')
    if (prompt) {
      try {
        window.location = `/caixaFechar/${caixa._id}`
      } catch (e) {
        alert(JSON.stringify(e.message))
      }
    }
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm text-center">
            {Number.isInteger(caixa.entrada) && Number.isInteger(caixa.saida) ? (
              <>
                <FaRegMoneyBillAlt size={26} /> R$
                <span data-cy="caixaDinheiro">
                  {caixa.entrada - caixa.saida}
                  ,00
                </span>{' '}
                |
                <BsCreditCard size={26} /> R$
                <span data-cy="caixaCartao">
                  {caixa.cartao}
                  ,00
                </span>
                <br />
                <button id="btnFecharCaixa" onClick={fecharCaixa} className="btn btn-danger my-2">
                  Fechar Caixa
                  {caixa.number}
                </button>
              </>
            ) : (
              <Loading size={60} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
