import React, { useState, useEffect, memo } from 'react'
import { getCurrentEvent } from '../services/sympla'
import SymplaListEvents from '../components/Portaria/SymplaListEvents'
import SymplaQrReader from '../components/Portaria/SymplaQrReader'
import Loading from '../components/Loading'

export default memo(function SymplaEntry() {
  const [events, setEvents] = useState([])
  const [event, setEvent] = useState({})
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    getCurrentEvent().then((data) => {
      setEvents(data)
      setLoaded(true)
    })
  }, [])

  return (
    <div>
      <h1>Sympla</h1>
      <div>
        {loaded ? (
          <>
            {!event.title ? (
              <SymplaListEvents setEvent={setEvent} events={events} />
            ) : (
              <SymplaQrReader event={event} setEvent={setEvent} />
            )}
          </>
        ) : (
          <Loading size={70} />
        )}
      </div>
      <div></div>
    </div>
  )
})
