import React from 'react'
import PropTypes from 'prop-types'

EstoqueBasico.propTypes = {
  estoque: PropTypes.string,
  setEstoque: PropTypes.string,
  alertaAlto: PropTypes.string,
  setAlertaAlto: PropTypes.string,
  setAlertaBaixo: PropTypes.string,
  alertaBaixo: PropTypes.string,
  unidade: PropTypes.string,
  setUnidade: PropTypes.string
}

export default function EstoqueBasico ({
  estoque, setEstoque, alertaAlto, setAlertaAlto, setAlertaBaixo, alertaBaixo, unidade, setUnidade
}) {
  return (
    <div>
      <div className="form-group">
        <label htmlFor="unidade">Unidade</label>
        <input type="text" value={unidade || ''} onChange={(e) => setUnidade(e.target.value)} className="form-control" id="unidade" placeholder="ml/copo/quantidade" />
      </div>
      <div className="form-group">
        <label htmlFor="exampleInputPassword1">Quantidade em Estoque</label>
        <input type="number" value={estoque} onChange={(e) => setEstoque(e.target.value)} className="form-control" id="estoque" placeholder="" />
      </div>

      <div className="form-group">
        <label htmlFor="exampleInputPassword1">Alerta Urgente</label>
        <input type="number" value={alertaAlto} onChange={(e) => setAlertaAlto(e.target.value)} className="form-control" id="alerta_alto" placeholder="" />
      </div>

      <div className="form-group">
        <label htmlFor="exampleInputPassword1">Alerta Médio</label>
        <input type="number" value={alertaBaixo} onChange={(e) => setAlertaBaixo(e.target.value)} className="form-control" id="alerta_baixo" placeholder="" />
      </div>
    </div>
  )
}
