import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'

const UserActivity = ({ activities }) => {
  return (
    <div>
      {activities.map((activity, index) => (
        <Card key={index} className="my-3">
          <Card.Header>{activity.client.name}</Card.Header>
          <Card.Body>
            <Card.Text>
              <strong>Administrador:</strong> {activity.adminFullname}
            </Card.Text>
            <Card.Text>
              <strong>Ação:</strong> {activity.verb}
            </Card.Text>
            <Card.Text>
              <strong>Details:</strong> {activity.details.msg}
            </Card.Text>
          </Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>Anterior:</strong> {JSON.stringify(activity.before)}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Atual:</strong> {JSON.stringify(activity.actual)}
            </ListGroup.Item>
          </ListGroup>
        </Card>
      ))}
    </div>
  )
}

export default UserActivity
