import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import api from '../services/api'

import Modal from '../components/Modal'
import Loading from '../components/Loading'

export default function CaixaDesfazer () {
  const params = useParams()

  const [data, setData] = useState({})
  const [modal, setModal] = useState({})

  console.log('diego')

  useEffect(() => {
    onLoad()
  }, [])

  async function voltaCredito () {
    const formData = {
      valor: data.valor,
      reason: 'Desfeita adição de crédito feita por ' + data.admin,
      product_name: 'Removido por ADM',
      quantity: '1',
      pagamento: data.pagamento,
      adminCaixaId: data.adminId

    }

    setModal({
      show: true,
      type: 'warning',
      title: 'Aguarde...',
      loading: true,
      msg: 'Tentando desfazer a venda'
    })

    try {
      await api.put(`users/${params.id}/remove/credit`, formData)
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Desfeito com sucesso!',
        link: '/caixaOperar'
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.response.data.message),
        link: '/'
      })
    }
  }

  async function onLoad () {
    try {
      const r = await api.get(`users/${params.id}/credito/${params.credito_id}`)
      console.log(r)
      if (Object.keys(data).length === 0) {
        setData({
          admin: r.data.admin_fullname,
          adminId: r.data.admin_id,
          comanda: r.data.comanda,
          usuario: r.data.user,
          hora: new Date(r.data.created_at).toLocaleString('pt-BR'),
          valor: r.data.value,
          pagamento: r.data.pagamento
        })
      }
    } catch (e) {
      if (e.response.status === 401) {
        alert('Você não tem permissão pra isso')
        window.location = '/caixa'
      }
      console.log(e)
      alert(e.response)
    }
  }

  function vorta () {
    window.history.back()
  }

  return (
    <div>
      <Modal modal={modal} />
      <h1>Tem certeza que deseja desfazer o crédito?</h1>
      <div className="text-center">
        {Number.isInteger(data.valor) ? (
          <>
            <h2>Informações</h2>
            Crédito adicionado por:
            <p>
              <br></br> <b>{data.admin}</b> @ {data.hora}
            </p>
            <h3> Comanda</h3>
            <h2> {data.comanda}</h2>
            <div>
              <h1>
                R$ {data.valor} | {data.pagamento}
              </h1>
              <button className="btn btn-success my-3 mx-3" onClick={vorta}>
                {' '}
                Voltar
              </button>
              <button id="desfazerVenda" className="btn btn-danger my-3" onClick={voltaCredito}>
                {' '}
                Sim
              </button>
            </div>
          </>
        ) : (
          <Loading size={60} />
        )}
      </div>
    </div>
  )
}
