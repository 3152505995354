import React from 'react'
// import PropTypes from 'prop-types'

import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import Login from './containers/Login'
import { isAuthenticated } from './services/auth'
import NotFound from './containers/NotFound'
import Home from './containers/Home'
import Bar from './containers/Bar'
import BarConsumir from './containers/BarConsumir'
import Entrada from './containers/Entrada'
import CaixaAbrir from './containers/CaixaAbrir'
import CaixaAdmin from './containers/CaixaAdmin'
import CaixaOperar from './containers/CaixaOperar'
import CaixaAdicionar from './containers/CaixaAdicionar'
import CaixaRemover from './containers/CaixaRemover'
import CaixasGeral from './containers/CaixasGeral'
import CaixaDetalhes from './containers/CaixaDetalhes'
import CaixaFechar from './containers/CaixaFechar'
import CaixaTrocoInicial from './containers/CaixaTrocoInicial'
import EntradaCriaCadastro from './containers/EntradaCriaCadastro'
import Creditos from './containers/Creditos'
import DesfazerVenda from './containers/DesfazerVenda'
import RefazerVenda from './containers/RefazerVenda'
import Selecao from './containers/Selecao'

import AssociarComanda from './containers/AssociarComanda'
import CriarUsuarioAssociarComanda from './containers/CriarUsuarioAssociarComanda'
import Relatorios from './containers/Relatorios'
import Pedidos from './containers/Pedidos'
import PedidosIniciar from './containers/PedidosIniciar'
import MeuTrabalho from './containers/MeuTrabalho'
import PedidosStatus from './containers/PedidosStatus'
import Feed from './containers/Feed'
import Estoque from './containers/Estoque'
import EstoqueEdita from './containers/EstoqueEdita'
import Usuario from './containers/Usuario'
import UsuarioEdita from './containers/UsuarioEdita'
import Usuarios from './containers/Usuarios'
import Portaria from './containers/Portaria'
import PortariaCaixa from './containers/PortariaCaixa'
import GuardaVolumes from './containers/GuardaVolumes'
import CaixaDesfazer from './containers/CaixaDesfazer'
import Promocoes from './containers/Promocoes'
import PromocaoNova from './containers/PromocaoNova'
import PromocaoEdita from './containers/PromocaoEdita'
import PromocaoEntregar from './containers/PromocaoEntregar'
import Eventos from './containers/Eventos'
import EventoNovo from './components/EventoNovo'
import EstoqueNumeros from './containers/EstoqueNumeros'
import CreditosTransferir from './containers/CreditosTransferir'
import UsuarioAlteraCreditos from './containers/UsuarioAlteraCreditos'
import MinhasAtividades from './containers/MinhasAtividades'
import VendasPorBar from './containers/VendasPorBar'
import Bloqueados from './containers/Bloqueados'
import AcoesPendentes from './containers/AcoesPendentes'
import Camera from './components/Camera'
import Saida from './containers/Saida'
import Caixa from './containers/Caixa'
import SymplaEntry from './containers/SymplaEntry'
import PeopleEvent from './containers/Entrada/PeopleEvent'
import Logs from './containers/Logs'

const PrivateRoutes = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />
}

const MyRoutes = () => (
  <Routes>
    <Route exact path="/login" element={<Login />} />
    <Route exact path="/logout" element={<Login />} />
    <Route path="*" element={<NotFound />} />
    <Route element={<PrivateRoutes />}>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/portariaSympla" element={<SymplaEntry />} />
      <Route exact path="/entrada" element={<Entrada />} />
      <Route exact path="/entrada/:comanda/:campo/:creditos/:entrada/:doc" element={<EntradaCriaCadastro />} />
      <Route exact path="/entrada/:comanda/:campo/:creditos/:entrada/:doc/:dn" element={<EntradaCriaCadastro />} />
      <Route exact path="/comanda/:user_id/:comanda/:creditos" element={<AssociarComanda />} />
      <Route exact path="/bar/seleciona" element={<Bar />} />
      <Route exact path="/bar" element={<Bar />} />
      <Route exact path="/bar/:id" element={<BarConsumir />} />
      <Route exact path="/bar/desfazer/:user_id/:creditUsageId" element={<DesfazerVenda />} />
      <Route exact path="/bar/refazer/:user_id/:usage_id" element={<RefazerVenda />} />
      <Route exact path="/caixa" element={<Caixa />} />
      <Route exact path="/caixaAdm" element={<CaixaAdmin />} />
      <Route exact path="/caixaAbrir" element={<CaixaAbrir />} />
      <Route exact path="/caixaRemover/:id" element={<CaixaRemover />} />
      <Route exact path="/caixaAdicionar/:id" element={<CaixaAdicionar />} />
      <Route exact path="/caixaOperar" element={<CaixaOperar />} />
      <Route exact path="/caixasGeral" element={<CaixasGeral />} />
      <Route exact path="/caixaDetalhes/:id" element={<CaixaDetalhes />} />
      <Route exact path="/caixaFechar/:id/:from?" element={<CaixaFechar />} />
      <Route exact path="/caixa/admin/:id" element={<CaixaTrocoInicial />} />
      <Route exact path="/user/:id/:credito_id/desfazer" element={<CaixaDesfazer />} />
      <Route exact path="/user/:id/credito" element={<Creditos />} />

      <Route exact path="/user/:campo/:comanda" element={<CriarUsuarioAssociarComanda />} />
      <Route exact path="/produtos" element={<AssociarComanda />} />
      <Route exact path="/relatorio" element={<Relatorios />} />
      <Route exact path="/pedidos" element={<Pedidos />} />
      <Route exact path="/meuTrabalho" element={<MeuTrabalho />} />
      <Route exact path="/pedidos/iniciar/:id" element={<PedidosIniciar />} />
      <Route exact path="/pedidos/status/:id/:status" element={<PedidosStatus />} />
      <Route exact path="/estoque/:id" element={<EstoqueEdita />} />
      <Route exact path="/estoque" element={<Estoque />} />
      <Route exact path="/numerosEstoque" element={<EstoqueNumeros />} />
      <Route exact path="/feed" element={<Feed />} />
      <Route exact path="/usuarios" element={<Usuarios />} />
      <Route exact path="/usuario" element={<Usuario />} />
      <Route exact path="/usuario/:id" element={<UsuarioEdita />} />
      <Route exact path="/portaria/:id" element={<PortariaCaixa />} />
      <Route exact path="/portaria" element={<Portaria />} />
      <Route exact path="/guardavolumes" element={<GuardaVolumes />} />
      <Route exact path="/promocoes" element={<Promocoes />} />
      <Route exact path="/promocao/nova/:id" element={<PromocaoNova />} />
      <Route exact path="/promocao/edita/:id" element={<PromocaoEdita />} />
      <Route exact path="/promocao/entregar/:user_id/:promo_id" element={<PromocaoEntregar />} />
      <Route exact path="/eventos" element={<Eventos />} />
      <Route exact path="/trasferirCredito" element={<CreditosTransferir />} />

      <Route exact path="/eventos/:id" element={<EventoNovo />} />
      <Route exact path="/selecao" element={<Selecao />} />
      <Route exact path="/vendasPorBar" element={<VendasPorBar />} />
      <Route exact path="/usuario/alterarCreditos/:id" element={<UsuarioAlteraCreditos />} />

      <Route exact path="/minhasAtividades" element={<MinhasAtividades />} />
      <Route exact path="/acoesPendentes" element={<AcoesPendentes />} />
      <Route exact path="/bloqueados" element={<Bloqueados />} />
      <Route exact path="/camera" element={<Camera />} />
      <Route exact path="/logs" element={<Logs />} />
      <Route exact path="/saida" element={<Saida />} />
      <Route exact path="/PessoasEvento" element={<PeopleEvent />} />
    </Route>
  </Routes>
)

export default MyRoutes
