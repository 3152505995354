import React, { useState } from 'react'

import { setBar } from '../services/auth'

export default function SelecionaBar () {
  const [selecionaBar, setSelecionaBar] = useState('')

  function salvaBar () {
    setBar(selecionaBar)
    window.location = '/bar'
  }

  return (
    <div id="selecionar-bar">
      <h3>Em qual bar você vai trabalhar hoje?</h3>
      <select id="sltBar" className="form form-control" onChange={(e) => setSelecionaBar(e.target.value)}>
        <option value="false">---</option>
        <option value="cima">Em Cima</option>
        <option value="baixo">Em Baixo</option>
      </select>
      <button id="btnSelectBar" onClick={salvaBar} className="w-100 btn-hight-40  btn btn-primary my-2">Enviar</button>
    </div>
  )
}
