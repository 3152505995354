import React, { useState, useEffect, memo } from 'react'
import { getCurrentEvent } from '../../services/events'

export default memo(function EventStatus({ setRunningEvent }) {
  const [evs, setEvs] = useState([])
  useEffect(() => {
    eventos()
  }, [])

  async function eventos() {
    const ev = await getCurrentEvent()
    setEvs(ev)
    if (setRunningEvent) {
      setRunningEvent(evs.length === 0)
    }
  }

  if (evs.length === 0) {
    return <div>Não há eventos nesse momento, você não pode colocar pessoas na fila</div>
  }
  return (
    <>
      {evs.map((e) => (
        <div key={e.id} className="alert alert-light my-4">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h4>{e.name}</h4>
                <p>
                  Começa em {new Date(e.inicio).toLocaleString('pt-BR')} e termina em{' '}
                  {new Date(e.fim).toLocaleString('pt-BR')}{' '}
                </p>
                <p>Valor Consumação: R$: {e.valor_consuma}</p>
                <p>Valor Entrada: R$: {e.valor_entrada}</p>
                <p>Limite de Pessoas: {e.limite_pessoas}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
})
