import React, { useEffect, useState } from "react";
import api from "../services/api";
import {
  getId,
  setClientId,
  setClientName,
  setRole,
  getRole,
  setFeatures,
} from "../services/auth";
import Modal from "../components/Modal";
import Loading from "../components/Loading";
import { setFav } from "../services/produtos";

export default function Selecao() {
  const [user, setUser] = useState({ client: [] });
  const [modal, setModal] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    onLoad();
    async function onLoad() {
      if (getRole() === "Operator") {
        const u = await api.get(`users/${getId()}`);
        setUser(u.data);
        setIsLoaded(true);
      } else {
        window.location = "/";
      }
    }
    // eslint-disable-next-line
  }, []);

  async function selectClient(clientId) {
    const ctz = window.confirm("Tem certeza que é esse?");
    if (ctz) {
      setModal({
        show: true,
        type: "warning",
        title: "Aguarde",
        msg: "Selecionando cliente",
        loading: true,
      });
      try {
        const res = await api.post("users/select/client", {
          clientId,
        });
        setClientId(res.data.clientId);
        setClientName(res.data.clientName);
        setRole(res.data.role);
        setFeatures(res.data.clientModules.join(","));
        window.location = "/";

        // setModal({
        //   show: true,
        //   type: 'success',
        //   title: 'Sucesso',
        //   msg: 'Selecionado com sucesso',
        //   link: '/'
        // })
      } catch (e) {
        setModal({
          show: true,
          type: "danger",
          title: "Erro",
          msg: JSON.stringify(e.message),
          link: "/selecao",
        });
      }
    }
  }

  return isLoaded ? (
    <div>
      <Modal modal={modal} />
      <h1>Selecione</h1>
      <p></p>

      <div className="text-center">
        {user.client
          .filter((c) => c.role !== "User")
          .map((e) => (
            <div key={e.id} className="m-2">
              <button
                id={e.name}
                className="btn btn-primary"
                onClick={() => selectClient(e.id)}
              >
                {e.name}
              </button>
              <hr />
            </div>
          ))}
      </div>
    </div>
  ) : (
    <Loading size={50} />
  );
}
