import React, { useEffect, useRef, useState } from 'react'
import api from '../services/api'

export default function Camera () {
  const videoRef = useRef(null)
  const photoRef = useRef(null)
  const stripRef = useRef(null)

  const [image, setImage] = useState(null)

  useEffect(() => {
    getVideo()
  }, [videoRef])

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia(
        {
          video: {
            width: 300,
            facingMode: 'environment'
          },
          audio: false
        }

      )
      .then(stream => {
        const video = videoRef.current
        video.srcObject = stream
        video.play()
      })
      .catch(err => {
        console.error('error:', err)
      })
  }

  async function sendPicture () {
    if (image === '') {
      return false
    }
    try {
      const res = await api.post('users/portaria/camera', {
        tel: '19998232185',
        photo: image
      })
      if (res.data.status === 'ok') {
        alert('Deu certo jovem')
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function comparePicture () {
    const photo = photoRef.current

    const data = photo.toDataURL('image/jpeg')
    try {
      const res = await api.post('users/portaria/compare', {
        tel: '19984319495',
        photo: data
      })
      if (res.data.status === 'ok') {
        alert('Deu certo jovem')
      } else {
        alert('A foto não corresponde ou não existe')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const paintToCanvas = () => {
    const video = videoRef.current
    const photo = photoRef.current
    const ctx = photo.getContext('2d')

    const width = 320
    const height = 240
    photo.width = width
    photo.height = height

    return setInterval(() => {
      ctx.drawImage(video, 0, 0, width, height)
    }, 200)
  }

  const takePhoto = () => {
    const photo = photoRef.current
    const strip = stripRef.current

    const data = photo.toDataURL('image/jpeg')

    // const link = document.createElement("a");
    const link = document.getElementById('alvo')
    link.href = data
    link.setAttribute('download', 'myWebcam')
    link.innerHTML = `<img src='${data}' alt='thumbnail'/>`
    strip.insertBefore(link, strip.firstChild)
    setImage(data)
  }

  return (
    <div>Camera

        <div>
        <div>

            <video
            onCanPlay={() => paintToCanvas()}
            ref={videoRef}
            className="player d-none"/>
        </div>

        <canvas ref={photoRef} className="photo" />
        <div className="photo-booth">
          <div ref={stripRef} className="strip" />
        </div>
        </div>
        < hr />
        <button onClick={() => takePhoto()}>Take a photo</button>
        <p id="alvo"></p>

        <h1>A data seria</h1>
        { image }

        <button onClick={() => sendPicture()}>Envia Foto</button>
        <button onClick={() => comparePicture()}>Compara Foto</button>
    </div>
  )
}
