import React, { useEffect, useState } from 'react'
import { dinheiro } from '../helpers/Formata'
import { getRole } from '../services/auth'
import PropTypes from 'prop-types'

import { getByClient } from '../helpers/Users'

ListaUserRecargas.propTypes = {
  user_id: PropTypes.string,
  recargas: PropTypes.array
}

export default function ListaUserRecargas ({ user_id, recargas }) {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let t = 0
    getByClient(recargas).forEach((r) => {
      t += r.value
    })
    setTotal(t)
  }, [recargas])

  function isItTransfer (via) {
    if (via) {
      if (via[0] === 'T') {
        return true
      }
      return false
    }
    return false
  }

  return (
    <div>
      Total: R$
      {' '}
      { dinheiro(total) }
      { getByClient(recargas).map((r) => (

        <div className="row" key={Math.random().toString(16).slice(2)}>
          <div className="col-sm-12 my-2">
            <div className="card">
              <div className="card-body p-4">
                <h5 className="card-title">{ new Date(r.created_at).toLocaleString('pt-BR')}</h5>
                <p className="card-text" id={isItTransfer(r.via) ? `transfer-${Math.abs(r.value)}` : ''}>
                  R$
{ dinheiro(r.value)}
                </p>
                <p className="card-text">
                  {r.via}
                  {' '}
                  |
                  {' '}
                  {r.pagamento}
                </p>
                <p className="card-text">{r.admin_fullname}</p>
                { isItTransfer(r.via) || getRole() !== 'Admin'
                  ? (
                  <>
                  </>
                    )
                  : (
                  <a id={`desfazer-${r.value}-${r.pagamento}`} className="btn btn-warning" href={`/user/${user_id}/${r._id}/desfazer`}>Desfazer</a>
                    ) }
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  )
}
