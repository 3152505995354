import React from 'react'
import './NotFound.css'

export default function NotFound () {
  return (
    <div className="NotFound text-center">
      <h3>Mals ai, página errada :/</h3>
      <div className='container'>
        <div className='text-center'>
          <img src='https://i.kym-cdn.com/photos/images/newsfeed/000/057/725/404kitten.jpg' />
        </div>
      </div>
    </div>
  )
}
