import React from 'react'
import PropTypes from 'prop-types'

CaixaBalanco.propTypes = {
  caixa: PropTypes.array
}

export default function CaixaBalanco ({ caixa }) {
  return (
    <div>
      <h4>Adições</h4>
      <div className="container adicao my-2">
        { caixa.entradas.map((c) => (
          <div key={c.created_at} className="row">
            <div className="col-4">
              {new Date(c.created_at).toLocaleTimeString('pt-br')}
            </div>
            <div className="col-4">
              {c.pagamento}
            </div>
            <div className="col-4" data-cy={c.valor}>
              R$:
              {' '}
              {c.valor}
              ,00
            </div>
          </div>
        ))}
      </div>
      <h4>Remoções</h4>
      <div className="my-2 remocao container">
        { caixa.saidas.map((c) => (
          <div key={c.created_at} className="row">
            <div className="col-4">
              {new Date(c.created_at).toLocaleTimeString('pt-br')}
            </div>
            <div className="col-4">
              {c.pagamento}
            </div>
            <div className="col-4" data-cy={c.valor}>
              R$:
              {' '}
              {c.valor}
              ,00
            </div>
          </div>
        ))}
      </div>

    </div>
  )
}
