import { getUserCredits } from '../helpers/Users'
import { getFeatures } from '../services/auth'
export function price (item) {
  return item.price * item.quantity
}

function sum (prev, next) {
  return prev + next
}

export function hasSaldo (p, user, totalItems, items) {
  if (getFeatures().includes('PosPago')) { return true }
  if (getNeededSaldo(p, totalItems, items) <= getUserCredits(user.credit)) {
    return true
  } else {
    return false
  }
}

export function getItemsValueSum (items) {
  if (items.length) {
    return items.map(price).reduce(sum);
  } else {
    return 0;
  }
}

export function getNeededSaldo (p, totalItems, items) {
  if (totalItems > 0) {
    return p.price + getItemsValueSum(items)
  } else {
    return p.price
  }
}
export function tryToAddItem (p, addItem, user, totalItems, items) {
  if (hasSaldo(p, user, totalItems, items)) {
    addItem(p)
  } else {
    alert('Saldo insuficiente!\nFaltam R$ ' + (getNeededSaldo(p)))
  }
}
