import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import api from '../services/api'

import Modal from '../components/Modal'

export default function PedidosStatus () {
  const params = useParams()

  useEffect(() => {
    status(params.id, params.status)
    // eslint-disable-next-line
    }, [])

  const [modal, setModal] = useState({})

  async function status (id, status) {
    setModal({
      show: true,
      type: 'warning',
      title: 'Aguarde',
      loading: true,
      msg: 'Finalizando o pedido'
    })

    try {
      await api.put('pedidos/' + params.id, {
        status
      })

      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Finalizado com sucesso!!',
        link: '/bar'
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.response.data.message),
        link: '/'
      })
    }
  }

  return (
        <div>
            <Modal modal={modal} />
        </div>
  )
}
