import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import api from '../services/api'
import { getUserComanda } from '../helpers/Users'

export default function DesfazerVenda() {
  const params = useParams()

  const [item, setItem] = useState({})
  const [user, setUser] = useState({})

  function voltar() {
    window.location = '/'
  }
  async function refazer() {
    try {
      await api.put(`users/${params.user_id}/consume/credits`, [{ ...item, id: item.itemId }])
      alert('Operação efetuada com sucesso')
      window.location = '/bar'
    } catch (e) {
      alert('Ação inválida, espere um pouco e tente novamente')
      window.location = '/bar'
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    try {
      const res = await api.get(`users/${params.user_id}`)
      setUser(res.data)
      setItem(res.data.credit_usage.find((x) => x._id === params.usage_id))
    } catch (e) {
      console.log(e.response)
    }
  }
  return (
    <div className="Venda">
      <h1>Refazer a venda</h1>
      <div className="lander">
        <p className="text-muted">Tem certeja que deseja refazer a venda desse produto?</p>
        <div id="target">
          <div className="text-center">
            <h2>Detalhes do Item</h2>
            <h3>{item.title}</h3>
            <p>Quantidade: {item.quantity}</p>
            <h3>Valor: {item.itemTotal}</h3>
            Lembre que o valor total será debitado de volta a comanda {getUserComanda(user.comanda)} ({user.name})
            <hr />
          </div>
        </div>
      </div>
      <div className="text-center">
        <button onClick={voltar} className="btn btn-success btn-lg bt">
          Voltar
        </button>{' '}
        |{' '}
        <button onClick={refazer} className="btn btn-danger btn-lg bt">
          Sim
        </button>
      </div>
    </div>
  )
}
