import React, { useState, useEffect, useRef } from 'react'

import { AppContext } from './lib/contextLib'
import MyRoutes from './Routes'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import { Link } from 'react-router-dom'
import { onError } from './lib/errorLib'
import { getToken, logout, timeLogged, getRole, getClientName } from './services/auth'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import './App.css'

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const loadingRef = useRef(false)

  useEffect(() => {
    if (!loadingRef.current) {
      onLoad()
      loadingRef.current = true
    }

    // eslint-disable-next-line
  }, [])

  async function onLoad() {
    try {
      if (getToken()) {
        userHasAuthenticated(true)
        if (timeLogged() > 60 * 24) {
          alert('Sua sessão expirou')
          userHasAuthenticated(false)
          logout()
          window.location = '/'
        }
      } else {
        userHasAuthenticated(false)
      }
    } catch (e) {
      if (e !== 'No current user') {
        onError(e)
      }
    }

    setIsAuthenticating(false)
  }

  function handleLogout() {
    userHasAuthenticated(false)
    logout()
    window.location = '/'
  }
  return (
    !isAuthenticating && (
      <div className="overley">
        <div className="App container py-3">
          <title>{getClientName()} - By RagazziD</title>
          <meta name="description" content="O melhor sistema de gestão de comanda e festas" />
          <meta property="og:title" content="RagazziD" />
          <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
            <Navbar.Brand className=" mx-4 my-2 font-weight-bold text-muted">
              <Link to="/#">{getClientName()}</Link>
            </Navbar.Brand>

            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Nav activeKey={window.location.pathname}>
                {isAuthenticated ? (
                  <>
                    {getRole() === 'Bar' ||
                    getRole() === 'BarCaixa' ||
                    getRole() === 'BarCaixaEstoque' ||
                    getRole() === 'BarCaixaEstoquePortaria' ? (
                      <>
                        <Nav>
                          <Link to="/bar">Bar</Link>
                        </Nav>
                        <Nav.Link to="/estoque">Estoque</Nav.Link>
                        <Nav.Link to="/meuTrabalho">Pedidos</Nav.Link>
                      </>
                    ) : (
                      <></>
                    )}

                    {getRole() === 'Caixa' ||
                    getRole() === 'BarCaixa' ||
                    getRole() === 'CaixaPortaria' ||
                    getRole() === 'BarCaixaEstoquePortaria' ? (
                      <>
                        <Nav.Link to="/entrada">Entrada</Nav.Link>
                        <Nav.Link to="/caixa">Caixa</Nav.Link>
                      </>
                    ) : (
                      <></>
                    )}

                    {getRole() === 'Estoque' ? (
                      <>
                        <Nav.Link to="/estoque">Estoque</Nav.Link>
                      </>
                    ) : (
                      <></>
                    )}

                    {getRole() === 'Admin' ? (
                      <>
                        <Nav className="m-2">
                          <Link to="/bar">Bar</Link>
                        </Nav>
                        <Nav className="m-2">
                          <Link to="/estoque">Estoque</Link>
                        </Nav>
                        <Nav className="m-2">
                          <Link to="/meuTrabalho">Pedidos</Link>
                        </Nav>
                        <Nav className="m-2">
                          <Link to="/entrada">Entrada</Link>
                        </Nav>
                        <Nav className="m-2">
                          <Link to="/caixa">Caixa</Link>
                        </Nav>
                        <Nav className="m-2">
                          <Link to="/feed">Feed</Link>
                        </Nav>
                      </>
                    ) : (
                      <></>
                    )}

                    <Nav.Link id="logout" onClick={handleLogout}>
                      Logout
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link href="/login">Login</Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            <MyRoutes />
          </AppContext.Provider>
          <div className="text-center fundoPreto">
            <hr />
            <a href="https://www.ragazzid.com.br/">RagazziD</a>
          </div>
        </div>
      </div>
    )
  )
}

export default App
