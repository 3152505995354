import api from './api'

export async function deleteCaixaById(id) {
  try {
    const response = await api.delete('caixa/' + id)
    if (response.status === 200) {
      return response.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}

export async function isCaixaOpen() {
  try {
    const res = await api.get('caixa/operador')
    return res.data
  } catch (e) {
    console.log(e)
    return false
  }
}
