const moment = require('moment')

export function usoDeHoje(credit) {
  const res = []
  const ontem = moment().add('-1', 'days')
  for (const c of credit) {
    if (moment(c.created_at).format() > ontem.format()) {
      res.push(c)
    }
  }
  return res
}

export function getDeltaHour(startDate, endDate) {
  const start = moment(startDate)
  const end = moment(endDate)
  const duration = moment.duration(end.diff(start))
  const hours = duration.asHours()
  return hours.toFixed(2)
}
