import React from 'react'
import { Link } from 'react-router-dom'
import { getFeatures } from '../services/auth'

export default function GuardaVolumesLinks () {
  return (
    getFeatures().includes('GuardaVolumes') &&
        <div className="buttons-container">
        <p>
            <Link to="/guardavolumes" className="d-grid gap-2">
                <button className="button btn btn-block btn-success ">
                <span className="button-font-size">Guarda Volumes</span>
                </button>
            </Link>
        </p>
        <hr />
        </div>
  )
}
