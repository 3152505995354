import React from 'react'
import PropTypes from 'prop-types'
import { timeSince } from '../lib/timeSince'
import api from "../services/api";

FilaDePedidos.propTypes = {
  pedidos: PropTypes.array,
};

export default function FilaDePedidos({ pedidos, setPedidos }) {
  async function deliveryItem(itemId) {
    console.log(itemId);
    await api.put("pedidos/" + itemId, {
      status: "entregar",
    });

    setPedidos(pedidos.filter((p) => p.id !== itemId));
  }
  return (
    <div>
      {pedidos.map((v) => (
        <div key={v.id} className="card alert alert-warning" style={{ width: '17rem' }}>
          <div className="card-body">
            {v.comanda ? (
              <h5 className="card-title">
                {v.user.name} ({v.comanda})
              </h5>
            ) : (
              <></>
            )}

            <p className="card-text">
              {v.item.quantity} - {v.item.title}
            </p>
            <p className="card-text">
              <b>{v.responsavel.split(' ')[0]}</b>
            </p>
            <p className="card-text">À {timeSince(new Date(v.entrada_em))}</p>
            <button onClick={() => deliveryItem(v.id)} className="btn btn-success">
              Concluir
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}
