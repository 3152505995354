import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import api from '../services/api'

import EstoqueBasico from '../components/EstoqueBasico'
import EstoqueParcial from '../components/EstoqueParcial'

export default function EstoqueEdita () {
  const params = useParams()

  const [produto, setProduto] = useState('')
  const [valor, setValor] = useState('')
  const [imagem, setImagem] = useState('')
  const [ativo, setAtivo] = useState('')
  const [estoque, setEstoque] = useState('')
  const [alertaAlto, setAlertaAlto] = useState('')
  const [alertaBaixo, setAlertaBaixo] = useState('')
  const [categoria, setCategoria] = useState('')
  const [destaque, setDestaque] = useState('')
  const [resultados, setResultados] = useState([])
  const [dependencias, setDependencias] = useState([])
  const [unidade, setUnidade] = useState('dependencias')

  useEffect(() => {
    onLoad()
  })

  async function onLoad () {
    if (produto === '') {
      const produto = await api.get('/bar/' + params.id)
      setProduto(produto.data.title)
      setValor(produto.data.price)
      setImagem(produto.data.image)
      setEstoque(produto.data.estoque)
      setAlertaBaixo(produto.data.alerta_baixo)
      setAlertaAlto(produto.data.alerta_alto)
      setCategoria(produto.data.category)
      setDestaque(produto.data.destaque)
      setUnidade(produto.data.unit)
      setDependencias(produto.data.dependencies)
      if (produto.data.ativo) {
        setAtivo('Ativo')
      } else {
        setAtivo('Inativo')
      }
    }
  }

  async function updateProduto (e) {
    const produtoData = {
      title: produto.toUpperCase(),
      price: valor,
      image: imagem,
      estoque,
      alerta_alto: alertaAlto,
      alerta_baixo: alertaBaixo,
      categoria,
      destaque,
      unit: unidade,
      dependencies: dependencias
    }

    e.preventDefault()
    try {
      await api.put('/bar/' + params.id, produtoData)
      alert('Atualizado com sucesso')
      window.location.reload(false)
    } catch (e) {
      alert(e.response.data.message)
    }
  }

  async function toggleAtiva () {
    await api.put('/bar/toogle/' + params.id)
    window.location = '/estoque/' + params.id
  }
  async function deleta () {
    const bora = window.confirm('Tem certeza que quer deletar esse produto?')
    if (bora) {
      await api.delete('/bar/' + params.id)
      alert('Deletado com sucesso')
      window.location = '/estoque'
    }
  }

  return (
        <div>
            <h1>Editando Produto</h1>
            <a href="/estoque" className="btn btn-dark">Voltar</a>
            <h3>{ ativo } </h3>

            <form>
                <div className="form-group">
                    <label htmlFor="produto">Nome do Produto</label>
                    <input value={produto} onChange={(e) => setProduto(e.target.value)} type="text" className="form-control" id="produto" placeholder="HEINKEN"></input>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Categoria</label>
                    <input value={categoria} type="text" onChange={(e) => setCategoria(e.target.value)} className="form-control" id="categoria" placeholder=""></input>
                </div>
                <div className="form-group">
                    <label htmlFor="price">Valor</label>
                    <input value={valor} type="number" className="form-control" id="price" onChange={(e) => setValor(e.target.value)} placeholder=""></input>
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Imagem</label>
                    <input value={imagem} type="text" onChange={(e) => setImagem(e.target.value)} className="form-control" id="image" placeholder=""></input>
                </div>
                <div className="img-fluid my-3 text-center">
                    <img src={imagem} width="100" alt={produto}></img>
                </div>
                { dependencias.length
                  ? (
                    <EstoqueParcial
                        resultados={resultados}
                        dependencias={dependencias}
                        setResultados={setResultados}
                        setDependencias={setDependencias}
                    />
                    )
                  : (
                    <EstoqueBasico estoque={estoque}
                        setEstoque={setEstoque}
                        alertaAlto={alertaAlto}
                        setAlertaAlto={setAlertaAlto}
                        setAlertaBaixo={setAlertaBaixo}
                        alertaBaixo={alertaBaixo}
                        setUnidade={setUnidade}
                        unidade={unidade}
                    />
                    )}

                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Destaque</label>
                    <input type="number" value={destaque} onChange={(e) => setDestaque(e.target.value)} className="form-control" id="destaque" placeholder="0"></input>
                </div>

                <button type="submit" onClick={updateProduto} className="btn btn-primary">Enviar</button>
            </form>
            <p className="my-3"><button type="submit" onClick={toggleAtiva} className="btn btn-warning">
                { ativo === 'Ativo'
                  ? (
                    <>Desativar</>
                    )
                  : (
                    <>Ativar</>
                    )}

                </button></p>
            <p className="my-3"><button type="submit" onClick={deleta} className="btn btn-danger">Deletar</button></p>
        </div>
  )
}
