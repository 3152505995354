import { CaixaDetalhesHistorico } from './../components/CaixaDetalhesHistorico'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'
import CaixaBalanco from '../components/CaixaBalanco'

export default function CaixaDetalhes () {
  const [caixa, setCaixa] = useState({ entradas: [], saidas: [], history: [] })
  const [caixaHistorico, setCaixaHistorico] = useState([])
  const params = useParams()

  useEffect(() => {
    getCaixa(params.id)
    // eslint-disable-next-line
      }, [])
  useEffect(() => {
    getCaixaHistory(params.id)
    // eslint-disable-next-line
      }, [])

  async function getCaixa (id) {
    const res = await api.get('caixa/' + id)
    setCaixa(res.data)
  }
  async function getCaixaHistory (id) {
    const res = await api.get('caixasHistory/' + id)
    setCaixaHistorico(res.data)
  }
  return (
    <div>
        <h2>Detalhes de Caixa</h2>
        <h3>{caixa.adminFullName}</h3>

      <div className="container">
        <div className="row">
          <div className="col">
            Dinheiro em Caixa
          </div>
          <div className="col">
            R$: {caixa.valorInicial + caixa.entrada}
          </div>
        </div>
        <div className="row">
          <div className="col">
            Valor em cartão
          </div>
          <div className="col">
            R$: { caixa.cartao }
          </div>
        </div>
      </div>

      <hr />
      Total em caixa: R$: {caixa.saldo + caixa.cartao},00
      <hr />
      <CaixaBalanco caixa={caixa} />
      <hr />
    <CaixaDetalhesHistorico u={caixa.history} />
    <CaixaDetalhesHistorico u={caixaHistorico} />
    </div>
  )
}
