import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router'
import LoaderButton from '../components/LoaderButton'
import { dinheiro } from '../helpers/Formata'
import api from '../services/api'

import { getUserComanda, getUserCredits } from '../helpers/Users'

export default function UsuarioAlteraCreditos () {
  const params = useParams()
  const [usuario, setUsuario] = useState({})
  const [alterarPara, setAlterarPara] = useState(0)
  const [motivo, setMotivo] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    onLoad()
  },
  [])

  function onLoad () {
    getUserData()
  }

  async function getUserData () {
    const getUser = await api.get('/users/' + params.id)
    setUsuario(getUser.data)
  }

  async function handleSubmit () {
    if (usuario.comanda === 0) {
      alert('Usuário não asssociado a nenhuma comanda')
      return false
    }
    setIsLoading(true)
    const confirm = window.confirm('Tem certeza que quer fazer isso?')
    if (confirm) {
      try {
        await api.post('users/alterarCreditos/' + params.id, {
          comanda: usuario.comanda,
          id: usuario.id,
          motivo,
          mudarPara: alterarPara
        })
        window.location = '/usuarios'
      } catch (e) {
        alert(e.message)
      }
    }
    setIsLoading(false)
  }

  return (
    <div>
        <h1>Editando Créditos</h1>
        <form>
        <div className="container">
            <h3>{usuario.name} {usuario.lastname}</h3>
            <div className="row my-2">
                <div className="col-sm">
                Comanda
                </div>
                <div className="col-sm my-2">
                    <b>{getUserComanda(usuario.comanda)}</b>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-sm">
                Créditos Atuais
                </div>
                <div className="col-sm my-2">
                    <b>R$ {dinheiro(getUserCredits(usuario.credit))}</b>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-sm">
                Alterar o valor dos créditos para:
                </div>
                <div className="col-sm">
                <input onChange={(e) => setAlterarPara(e.target.value)} className="form form-control" type="number"/>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-sm">
                Motivo
                </div>
                <div className="col-sm">
                <input onChange={(e) => setMotivo(e.target.value)} className="form form-control" type="text"/>
                </div>
            </div>
            <div className="text-center">
                <LoaderButton
                isLoading={isLoading}
                    onClick={handleSubmit}
                    className="my-2 btn btn-warning"
                >
                    Enviar
                </LoaderButton>
            </div>
        </div>
        </form>

    </div>
  )
}
