import React, { useState } from 'react'

import LoaderButton from '../components/LoaderButton'
import { dinheiro } from '../helpers/Formata'
import api from '../services/api'

import { getUserCredits } from '../helpers/Users'

import Modal from '../components/Modal'

export default function CreditosTransferir () {
  const [origem, setOrigem] = useState(0)
  const [destino, setDestino] = useState(0)
  const [userOrigem, setUserOrigem] = useState({})
  const [dinheiroOrigem, setDinheiroDestino] = useState(0)
  const [userDestino, setUserDestino] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({})

  async function handleSubmit (e) {
    setIsLoading(true)

    e.preventDefault()
    if (parseInt(origem) !== 1 || parseInt(destino) !== 1) {
      const getOrigem = await api.get('users/comanda/' + origem)
      const getDestino = await api.get('users/comanda/' + destino)
      if (getOrigem.data.length > 0 && getDestino.data.length > 0) {
        setUserOrigem(getOrigem.data[0])
        setUserDestino(getDestino.data[0])
        setDinheiroDestino(getUserCredits(userOrigem.credit))

        document.getElementById('alvoTransferencia').style.display = 'block'
      } else {
        setModal({
          show: true,
          type: 'danger',
          title: 'Erro',
          msg: 'Comandas inválidas',
          link: '/trasferirCredito'
        })
      }
    } else {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: 'Não pode mexer na comanda de perda né?',
        link: '/trasferirCredito'
      })
    }

    setIsLoading(false)
  }

  async function transferir () {
    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando transferir',
      msg: 'Aguarde...'
    })
    try {
      await api.post('/users/transferirDinheiroEntreComandas', {
        origem: {
          id: userOrigem.id,
          comanda: userOrigem.comanda,
          valor: dinheiroOrigem
        },
        destino: {
          id: userDestino.id,
          comanda: userDestino.comanda
        }
      })
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Deu tudo certo!',
        link: '/'
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: e.response.data.message,
        link: '/'
      })
    }
  }

  return (
    <div>
      <Modal modal={modal} />
      <h1>Transferir Créditos entre Comandas</h1>
      <form>
      <div className="container">
      <div className="row">
        <div className="col-sm">
          Comanda de Origem
        </div>
        <div className="col-sm">
          <input type="number" onChange={(e) => setOrigem(e.target.value)} className='form form-control' id="origem" />
        </div>
      </div>
      <div className="row my-4">
        <div className="col-sm">
          Comanda de Destino
        </div>
        <div className="col-sm">
        <input type="number" onChange={(e) => setDestino(e.target.value)} className='form form-control' id="destino" />
        </div>
      </div>
    </div>
    <div className='text-center'>
    <LoaderButton
    id="btnVerifica"
    isLoading={isLoading}
    onClick={handleSubmit}
    >Verificar</LoaderButton>
    </div>
      </form>
      <hr />
      <div id="alvoTransferencia" style={{ display: 'none' }}>
        <div className='text-center'>
          <h4>Enviar os créditos da comanda {origem} para comanda {destino}?</h4>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm">
            <div className="card alert alert-warning">
              <div className="card-body">
                <h5 className="card-title">{userOrigem.name} {userOrigem.lastname}</h5>
                <p className="card-text">Créditos Atuais: R$ {dinheiro(getUserCredits(userOrigem.credit))}</p>
                <p className="card-text">Quanto enviar?</p>
                <p className="card-text">
                  <input type="number" id="transferOrigin" className="form form-control" value={dinheiroOrigem} onChange={(e) => setDinheiroDestino(e.target.value)} />
                </p>
              </div>
            </div>
            </div>
            <div className="col-sm">
            <div className="card alert alert-danger">
              <div className="card-body">
              <h5 className="card-title">{userDestino.name} {userDestino.lastname}</h5>
                <p className="card-text">Créditos Atuais: R$ {dinheiro(getUserCredits(userDestino.credit))}</p>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <h3>Você tem certeza que quer transferir?</h3>
          <LoaderButton id="btnTransferir" onClick={transferir} className="btn btn-danger">Sim, eu tenho</LoaderButton>
        </div>
      </div>
    </div>
  )
}
