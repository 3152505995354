import React from 'react'
import { Link } from 'react-router-dom'
import { getFeatures } from '../services/auth'

export default function BarLinks () {
  return (
    getFeatures().includes('Bar') &&
        <div className="buttons-container">
            <Link to="/bar" className="">
                <button className="button btn btn-block btn-primary"><span className="button-font-size">Bar</span></button>
            </Link>
            {/* <Link to="/barSplit" className="">
                <button className="button btn btn-block btn-warning"><span className="button-font-size">Vender Dividido</span></button>
            </Link> */}
        </div>
  )
}
