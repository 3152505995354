import React, { useState } from 'react'
import api from '../services/api'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { ListItemsReport, ListItemsReportUsers } from '../components/ListItemsReport'

const moment = require('moment')

export default function Relatorios () {
  const [today] = useState(new Date())
  const [priorDate] = useState(new Date().setDate(today.getDate() - 1))
  const [inicio, setInicio] = useState(priorDate)
  const [fim, setFim] = useState(today)
  const [caixaArrecadado, setCaixaArrecadado] = useState([])
  const [barVendido, setBarVendido] = useState([])
  const [usuariosEvento, setUsuariosEvento] = useState([])

  async function usuarios () {
    const user = await api.get('users/r/naKit')
    if (user.data.data) { setUsuariosEvento(user.data.data) }
  }

  function isLongerThan30days (inicio, fim) {
    const d1 = moment(inicio)
    const d2 = moment(fim)
    return (d2.diff(d1) / 60 / 60 / 24 / 1000) > 30
  }

  async function caixa () {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    if (isLongerThan30days(inicio, fim)) {
      alert('So é permitido verificar num periodo de 30 dias')
      alert('Mude a data')
      return false
    }
    const p = await api.get(`users/r/caixaVendidos/${i}/${f}`)
    setCaixaArrecadado(p.data)
  }

  async function bar () {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    if (isLongerThan30days(inicio, fim)) {
      alert('So é permitido verificar num periodo de 30 dias')
      alert('Mude a data')
      return false
    }
    const p = await api.get(`users/r/barVendidos/${i}/${f}`)
    setBarVendido(p.data)
  }

  async function contagemVendidos () {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    if (isLongerThan30days(inicio, fim)) {
      alert('So é permitido verificar num periodo de 30 dias')
      alert('Mude a data')
      return false
    }
    const p = await api.get(`users/r/contagemVendidos/${i}/${f}`)
    let res = ''
    p.data.data.forEach((r) => {
      res += `<li>${r._id},${r.total}</li>`
    })
    const target = document.getElementById('cVendidos')
    target.innerHTML = `
            <h5>Produtos Vendidos</h5>
            <ul>
                ${res}
            </ul>
            <hr />
        `
  }

  async function arrecadadoVendidos () {
    const i = new Date(inicio).toISOString()
    const f = new Date(fim).toISOString()
    if (isLongerThan30days(inicio, fim)) {
      alert('So é permitido verificar num periodo de 30 dias')
      alert('Mude a data')
      return false
    }
    const p = await api.get(`users/r/arrecadadoVendidos/${i}/${f}`)

    let res = ''
    let total = 0
    p.data.data.forEach((r) => {
      res += `<li>${r._id},${r.total}</li>`
      total += r.total
    })
    const target = document.getElementById('tVendidos')
    target.innerHTML = `
            <h5>Produtos Vendidos</h5>
            <ul>
                ${res}
            </ul>
            Total arrecadado: R$ <b>${total}</b>
            <hr />
        `
  }

  async function recargas () {
    try {
      const i = new Date(inicio).toISOString()
      const f = new Date(fim).toISOString()
      if (isLongerThan30days(inicio, fim)) {
        alert('So é permitido verificar num periodo de 30 dias')
        alert('Mude a data')
        return false
      }
      const p = await api.get(`users/r/recargas/${i}/${f}`)
      let res = ''
      let total = 0
      p.data.data.forEach((r) => {
        res += `<li>${r.name} ${r.lastname} - R$${r.total},00</li>`
        total += r.total
      })
      const target = document.getElementById('tRecarga')
      target.innerHTML = `
                <h5>Total de Recargas</h5>
                <ul>
                    ${res}
                </ul>
                Total arrecadado: R$ <b>${total}</b>
                <hr />
            `
    } catch (e) {
      console.log(e)
      console.log(e.response)
    }
  }

  return (
    <div>
        <h1>Números</h1>
        <h3>Período</h3>
        <form>
            <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Início</label>
                <div className="col-sm-10">
                <DatePicker
                id="inicio"
                    selected={inicio}
                    onChange={(date) => setInicio(date)}
                    timeInputLabel="Horário:"
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className="form-control"
                    showTimeInput
                />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Fim</label>
                <div className="col-sm-10">
                <DatePicker
                id="fim"
                    selected={fim}
                    onChange={(date) => setFim(date)}
                    timeInputLabel="Horário:"
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className="form-control"
                    showTimeInput
                />
                </div>
            </div>
        </form>

        <hr />
        <div>
            <h3>
                Total arrecado por Caixa
                | <button id="totalCaixa" onClick={caixa} className="btn btn-primary"> -&gt; </button></h3>
            <div id="caixaArrecadado">
                <ListItemsReport items={caixaArrecadado} />
            </div>
        </div>

        <hr />
        <div>
            <h3>
                Total arrecado por Bar
                | <button id="totalBar" onClick={bar} className="btn btn-primary">-&gt;</button></h3>
            <div id="caixaArrecadado">

                <ListItemsReport items={barVendido} />

            </div>
        </div>

        <hr />
        <div>
            <h3>
                Usuários que vieram/estão no evento
                | <button id="userKit" onClick={usuarios} className="btn btn-primary">-&gt;</button></h3>
            <div id="usuarios">

            <ListItemsReportUsers items={usuariosEvento} />
            </div>
        </div>
        <div>
            <h3>Contagem de produtos vendidos
            | <button id="contagemVendidos" onClick={contagemVendidos} className="btn btn-primary">-&gt;</button>
            </h3>
            <div id="cVendidos"></div>
        </div>

        <div>
            <h3>Total arrecadado pelos produtos
            | <button id="totalArrecProd" onClick={arrecadadoVendidos} className="btn btn-primary">-&gt;</button>
            </h3>
            <div id="tVendidos"></div>
        </div>

        <div>
            <h3>Total pago por usuários
            | <button id="totalPgUsuarios" onClick={recargas} className="btn btn-primary">-&gt;</button>
            </h3>
            <div id="tRecarga"></div>
        </div>
    </div>
  )
}
