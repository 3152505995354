import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CartProvider } from 'react-use-cart'

import './index.css'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from './App'

import reportWebVitals from './reportWebVitals'
import DevBanner from './components/Banner/DevBanner'

// bootstrap-theme file for bootstrap 3 only

const ENV = process.env.REACT_APP_ENVIRONMENT

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <CartProvider>
        {ENV === 'development' && <DevBanner />}
        <App />
        {ENV === 'development' && <DevBanner />}
      </CartProvider>
    </BrowserRouter>
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
