import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import api from "../services/api";
import LoaderButton from "../components/LoaderButton";
import { telefone } from "../helpers/Formata";

import Modal from "../components/Modal";
import { getClientId, getRole } from '../services/auth'
import PaymentType from "../components/Caixa/PaymentType";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

export default function PortariaCaixa() {
  const params = useParams();

  const [isL, setIsL] = useState(false);
  const [credito, setCredito] = useState("");
  const [tel, setTel] = useState("");
  const [nome, setNome] = useState("");
  const [comanda, setComanda] = useState("");
  const [portaria, setPortaria] = useState({});
  const [pagamento, setPagamento] = useState("");
  const [modal, setModal] = useState({});
  const [extraPaymentMethod, setExtraPaymentMethod] = useState(false);
  const [extraPaymentType, setExtraPaymentType] = useState("vazio");
  const [extraValue, setExtraValue] = useState(0);

  function isFormOk() {
    return credito >= 0 && comanda > 0 && pagamento != ''
  }
  function adcPaymentMethod() {
    setExtraPaymentMethod(!extraPaymentMethod);
    setExtraPaymentType("vazio");
    setExtraValue(0);
  }

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    if (nome === "") {
      const u = await api.get("users/" + params.id);
      setTel(u.data.tel);
      setNome(u.data.name + " " + u.data.lastname);
      setPortaria(u.data.portaria.find((p) => String(p.client.id) === String(getClientId())))
    }
  }

  async function associar() {
    setIsL(true);
    const bora = window.confirm(
      `Associar ${nome} a comanda ${comanda}\ne colocar R$${credito}?`
    );
    if (bora) {
      if (parseInt(credito, 10) <= 100) {
        if (comanda <= 15 && parseInt(credito, 10) > 60 && getRole() !== 'Admin') {
          alert('Apenas Admin podem por créditos\nAcima de R$ 60,00 para comandas de 1 a 15')
          alert('A operação não foi executada')
          setIsL(false)
          return 0
        }
        try {
          setModal({
            show: true,
            type: "warning",
            title: "Trabalhando",
            msg: "Aguarde...",
          });
          const ev = await api.get("events/now");
          const formData = ev.data[0];

          await api.post("users/associarEporCreditos", {
            comanda: parseInt(comanda),
            values: [
              {
                valor: parseInt(credito),
                pagamento,
              },
              {
                valor: parseInt(extraValue),
                pagamento: extraPaymentType,
              },
            ],
            reason: "Caixa Kitnet",
            id: params.id,
            ev_data: formData,
          });
          setModal({
            show: true,
            type: "success",
            title: "Sucesso",
            msg: "Deu tudo certo!",
            link: "/caixa",
          });
        } catch (e) {
          setIsL(false);
          setModal({
            show: true,
            type: "danger",
            title: "Erro",
            msg: JSON.stringify(e.response.data),
            link: "/caixa",
          });
        }
      } else {
        setIsL(false);
        setModal({
          show: true,
          type: "danger",
          title: "Erro",
          msg: "O crédito não pode ser maior que R$ 100,00!",
          link: "/caixa",
        });
      }
    }
    setIsL(false);
  }


  return (
    <div>
      <Modal modal={modal} />
      <h1>Caixa</h1>

      <h3>Entrada</h3>
      <div className="alert alert-primary">
        <p>
          <b>{nome}</b>
        </p>
        <p>Tel: {telefone(tel)}</p>
        {portaria.obs}
      </div>
      <div className="form form-label">
        Comanda
        <input
          id="input-comanda"
          className="form form-control"
          onChange={(e) => setComanda(e.target.value)}
          type="number"
        ></input>
      </div>
      <div className="my-2 form form-label">
        Créditos
        <input
          id="input-creditos"
          className="form form-control"
          onChange={(e) => setCredito(e.target.value)}
          type="number"
          value={credito}
        ></input>
      </div>

      <PaymentType
        id={""}
        name="pagamento"
        setPayment={setPagamento}
        payment={pagamento}
      />

      <hr />
      <div className="text-center" onClick={adcPaymentMethod}>
        <p>Adicionar mais uma forma de pagamento</p>
        {extraPaymentMethod ? (
          <AiOutlineMinusCircle size={30} />
        ) : (
          <AiOutlinePlusCircle size={30} />
        )}
      </div>

      {extraPaymentMethod && (
        <>
          <div className="my-2 form form-label">
            Créditos
            <input
              id="input-creditos"
              className="form form-control"
              onChange={(e) => setExtraValue(e.target.value)}
              value={extraValue}
              type="number"
            ></input>
          </div>
          <PaymentType
            id={"extra"}
            payment={extraPaymentMethod}
            setPayment={setExtraPaymentType}
            name="extraPayment"
          />
        </>
      )}
      <hr />

      <LoaderButton
        isLoading={isL}
        onClick={associar}
        id="btn-associar"
        className="btn btn-success my-3"
        disabled={!isFormOk()}
      >
        Associar e Adicionar
      </LoaderButton>
      {/* <LoaderButton isLoading={isL} onClick={removarDaFila} className="btn btn-success my-3">Remover usuário da fila</LoaderButton> */}
    </div>
  );
}
