import React, { useEffect, useState } from 'react'
import api from '../services/api'

import CaixaAbertos from '../components/CaixaAbertos'

export default function CaixasGeral () {
  useEffect(() => {
    getCaixas()
  }, [])

  const [caixas, setCaixas] = useState([])

  async function getCaixas () {
    const res = await api.get('/caixa/todos')
    setCaixas(res.data)
  }
  return (
    <div>
        <h2>Todos os Caixas</h2>
    <CaixaAbertos caixas={caixas} detalhes={true} />
    </div>
  )
}
