import { AddingCredits } from './Caixa/AddingCredits'
import { PayingCredits } from './Caixa/PayingCredits'
import React, { useState, memo } from 'react'
import Form from 'react-bootstrap/Form'
import LoaderButton from './LoaderButton'
import { dinheiro, telefone } from '../helpers/Formata'
import ListaUserPedidos from './ListaUserPedidos'

import { getFeatures } from '../services/auth'

import api from '../services/api'

import { getUserCredits } from '../helpers/Users'
import RechargeHistory from './RechargeHistory'
import { Link } from "react-router-dom";

export default memo(function CaixaComanda() {
  const [comanda, setComanda] = useState('')
  const [isLoading, setIsLoading] = useState('')
  const [user, setUser] = useState({ credit: [], credit_usage: [], credit_charge: [] })
  function validateForm() {
    return comanda.length > 0
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    const x = document.getElementById('target')
    const html = ''
    try {
      const res = await api.get(`users/comanda/${comanda}`)
      if (res.data.length) {
        setUser(res.data[0])

        const invalida = document.getElementById('comandaInvalida')
        invalida.classList.add('d-none')
        const valida = document.getElementById('comandaValida')
        valida.classList.remove('d-none')
      } else {
        const invalida = document.getElementById('comandaInvalida')
        invalida.classList.remove('d-none')
        const valida = document.getElementById('comandaValida')
        valida.classList.add('d-none')
      }
      setIsLoading(false)
    } catch (e) {
      alert(JSON.stringify(e.response))
      setIsLoading(false)
    }
    setIsLoading(false)

    x.innerHTML = html
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="comanda">
          <Form.Label> Comanda </Form.Label>{' '}
          <Form.Control autoFocus type="number" value={comanda} onChange={(e) => setComanda(e.target.value)} />{' '}
        </Form.Group>{' '}
        <LoaderButton
          block
          size="lg"
          className="my-2 w-100"
          type="submit"
          id="buscaComanda"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Procurar
        </LoaderButton>
      </Form>{' '}
      <div className="target" id="target" />
      <div id="target" />
      <hr />
      <div
        id="comandaValida"
        className={getUserCredits(user.credit) >= 0 ? 'alert alert-success d-none' : 'alert alert-danger d-none'}
      >
        <h3>
          {user.name} {user.lastname}
        </h3>
        <p>{telefone(user.tel)}</p>

        <hr />
        {!getFeatures().includes('PosPago') && (
          <>
            <AddingCredits dinheiro={dinheiro} getUserCredits={getUserCredits} user={user} />
            <RechargeHistory user={user} />
          </>
        )}

        {getFeatures().includes('PosPago') && (
          <>
            <PayingCredits dinheiro={dinheiro} getUserCredits={getUserCredits} user={user} />
            <p className="text-center">
              <Link to={`user/${user.id}/credito`} className="btn btn-primary btn-lg" id="btnAdicionarCreditos">
                Adicionar Créditos
              </Link>
            </p>
          </>
        )}

        {user.credit_usage.length ? (
          <div className="container">
            <h3>Uso de Créditos</h3>
            <ListaUserPedidos user={user} parcial />
            {/* <RechargeHistory user={user} /> */}
          </div>
        ) : (
          <div>
            <h3 className="text-center">Ainda não consumiu nada hoje</h3>
          </div>
        )}
      </div>
      <div id="comandaInvalida" className="alert alert-warning d-none">
        <h2>Comanda inválida</h2>
      </div>
    </div>
  )
})
