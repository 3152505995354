export const roles = [
  'Admin',
  'Bar',
  'BarCaixa',
  'BarCaixaEstoquePortaria',
  'BarCaixaPortaria',
  'BarPortaria',
  'Caixa',
  'CaixaPortaria',
  'Estoque',
  'GuardaVolumes',
  'Portaria'
]
