import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import api from '../services/api'

export default function PedidosIniciar () {
  const params = useParams()
  useEffect(() => {
    pegar()
  })

  async function pegar () {
    await api.put('pedidos/' + params.id, {
      status: 'pegar'
    })
    alert('Sucesso, bom trabalho!')
    window.location = '/meuTrabalho'
  }
  return (
        <div>

        </div>
  )
}
