import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'

export default function AssociarComanda () {
  useEffect(() => {
    onLoad()
  })

  const params = useParams()
  async function onLoad () {
    try {
      await api.post(
        'users/' + params.user_id + '/comanda', { comanda: params.comanda, creditos: params.creditos }
      )
    } catch (e) {
      alert('Falha\n' + e.response.data.message)
    }
  }

  return (
        <div className = "AssociarComanda">
            <h1> Feito com sucesso!</h1>
        </div>
  )
}
