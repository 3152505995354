import React, { useState, useEffect } from 'react'
import Loading from '../Loading'
import { checkEventInTimeRange, createEvent } from '../../services/events'
import { Link } from 'react-router-dom'

export default function SymplaListEvents({ events, setEvent }) {
  return (
    <>
      <h3>Eventos</h3>
      <div className="container">
        <p>Selecione um evento para fazer checkin</p>
      </div>
      <div>
        {events.map((e) => (
          <EventCard
            key={e.id}
            id={e.id}
            startDate={e.start_date}
            endDate={e.end_date}
            title={e.name}
            location={e.address.name}
            description={e.detail}
            setEvent={setEvent}
          />
        ))}
      </div>
    </>
  )
}

const EventCard = ({
  id,
  title,
  startDate,
  endDate,
  location,
  // description,
  setEvent
}) => {
  const [isEventCreated, setIsEventCreated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    checkEventInTimeRange(startDate, endDate).then((data) => {
      setIsEventCreated(data.length > 0)
      setIsLoading(false)
    })
  }, [])

  async function importEventFromSympla() {
    setIsLoading(true)
    try {
      const res = await createEvent({
        name: title,
        inicio: new Date(startDate),
        fim: new Date(endDate),
        limite_pessoas: 10000,
        valor_entrada: 0,
        valor_consuma: 0
      })
      if (res) {
        setIsEventCreated(true)
      } else {
        alert('N foi possivel importar o evento, pq já existe um evento acontecendo no mesmo horário')
      }
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }
  return (
    <div className="card m-4">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">
          {startDate} - {endDate}
        </p>
        <p className="card-text">{location}</p>
        {/* <p className="card-text">{description}</p> */}
        <button className="btn btn-primary" onClick={() => setEvent({ id, title })}>
          Selecionar
        </button>
        {isLoading ? (
          <Loading size={30} />
        ) : (
          <div className="my-2">
            {!isEventCreated ? (
              <button className="btn btn-warning" onClick={importEventFromSympla}>
                Importar p/ Sistema Local
              </button>
            ) : (
              <p>
                Evento já importado, se precisar remover, use o link <Link to="/eventos">Eventos</Link>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
