import React, { useState, useEffect } from 'react'

import { BsSearch } from 'react-icons/bs'
import api from '../services/api'

import { getUserRole } from '../helpers/Users'
import { getClientId } from '../services/auth'

export default function Usuarios() {
  const [users, setUsers] = useState([])
  const [userSearch, setUserSearch] = useState([])
  const [phone, setPhone] = useState([])
  const [comanda, setComanda] = useState('')
  const [kitUsers, setKitUsers] = useState([])

  async function getUsers() {
    const u = await api.get('users/admin')
    setUsers(u.data)
  }

  async function procuraUsuarios(byName) {
    let ku
    if (byName) {
      ku = await api.get('users/get/' + userSearch)
    } else {
      ku = await api.get('users/get/' + phone.replace(/\D/g, ''))
    }

    if (ku.data) {
      setKitUsers(ku.data)
    } else {
      setKitUsers([])
    }
  }

  async function procuraUsuariosPorComanda() {
    const ku = await api.get('users/comanda/' + comanda)
    if (ku.data) {
      setKitUsers(ku.data)
    } else {
      setKitUsers([])
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <div>
      <h1>Usuários</h1>
      <h2>Que trabalham</h2>
      <div>
        <ul>
          {users.map((u) => (
            <li key={u.id}>
              <a href={'usuario/' + u.id}>
                {u.name} {u.lastname}
              </a>{' '}
              ({getUserRole(u.client, getClientId())})
            </li>
          ))}
        </ul>
      </div>
      <a href="/usuario" id="btnNovoUsuario" className="btn btn-primary">
        Criar um novo usuário
      </a>
      <h2>Usuários por telefone</h2>
      <div className="container">
        <div className="row">
          <div className="col-10">
            <input type="text" id="busca" className="form-control" onChange={(e) => setPhone(e.target.value)}></input>
          </div>
          <div className="col-2">
            <button onClick={() => procuraUsuarios(false)} id="buscaUsuarios" type="button" className="btn btn-primary">
              <BsSearch />
            </button>
          </div>
        </div>
      </div>
      <h2>Usuários por Nome</h2>
      <div className="container">
        <div className="row">
          <div className="col-10">
            <input type="text" id="" className="form-control" onChange={(e) => setUserSearch(e.target.value)}></input>
          </div>
          <div className="col-2">
            <button onClick={() => procuraUsuarios(true)} id="buscaUsuarios" type="button" className="btn btn-primary">
              <BsSearch />
            </button>
          </div>
        </div>
      </div>
      <h2>Pesquisar por comanda</h2>
      <div className="container">
        <div className="row">
          <div className="col-10">
            <input
              type="text"
              id="buscaPorComanda"
              className="form-control"
              onChange={(e) => {
                setComanda(e.target.value)
              }}
            ></input>
          </div>
          <div className="col-2">
            <button
              onClick={procuraUsuariosPorComanda}
              id="procuraUsuariosPorComanda"
              type="button"
              className="btn btn-primary"
              onChange={(e) => {
                setComanda(e.target.value)
              }}
            >
              <BsSearch />
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div id="my-4">
        <ul>
          {kitUsers.map((k) => (
            <li key={k.id}>
              <a id={`u-${k.tel}`} href={'usuario/' + k.id}>
                {k.name} {k.lastname}
              </a>{' '}
              ({k.tel}) - {k.role}{' '}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
