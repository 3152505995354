// import React, { useEffect } from 'react'
// import api from '../services/api'
// import { getPedidosFinalizadosView, pedidosAguardandoView, pedidosTrabalhandoView } from '../views/pedidos'

// import './Pedidos.css'

// export default function Pedidos () {
//   useEffect(() => {
//     onLoad()
//   }, [])

//   async function onLoad () {
//     getPedidosPendentes()
//     getPeditosTrabalhando()
//     getPedidosFinalizados()
//     window.setInterval(function () {
//       getPedidosPendentes()
//       getPeditosTrabalhando()
//       getPedidosFinalizados()
//     }, 5000)
//   }
//   async function getPedidosPendentes () {
//     const pendentes = await api.get('pedidos/abertos')
//     const novos = document.getElementById('novos')
//     if (novos) {
//       novos.innerHTML = pedidosAguardandoView(pendentes.data)
//     }
//   }
//   async function getPeditosTrabalhando () {
//     const trabalhando = await api.get('pedidos/trabalhando')
//     const trabalhando_div = document.getElementById('trabalhando')
//     if (trabalhando_div) {
//       trabalhando_div.innerHTML = pedidosTrabalhandoView(trabalhando.data)
//     }
//   }
//   async function getPedidosFinalizados () {
//     const entregues = await api.get('pedidos/fechados')
//     const entregues_div = document.getElementById('entregues')
//     if (entregues_div) {
//       entregues_div.innerHTML = getPedidosFinalizadosView(entregues.data)
//     }
//   }
//   return (
//         <div className="container pedidos">
//             <div className="row">
//                 <div id="novos" className="col-sm">
//                 </div>
//                 <div id="trabalhando" className="col-sm">
//                 </div>
//                 <div id="entregues" className="col-sm">
//                 </div>
//             </div>
//         </div>
//   )
// }

import React from "react";

export default function Pedidos() {
  return <div>Pedidos</div>;
}
