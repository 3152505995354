import React from 'react'
import { Link } from 'react-router-dom'

import { getFeatures } from '../services/auth'

export default function CaixaLinks () {
  return (
    getFeatures().includes('Estoque') &&
        <div className="buttons-container">
            <p>
                <Link to="/estoque" className="d-grid gap-2">
                    <button className="btn btn-danger ">
                    <span className="button-font-size">Estoque</span></button>
                </Link>
            </p>
            <p>
                <Link to="/vendasPorBar" className="d-grid gap-2">
                     <button className="btn btn-warning ">
                     <span className="button-font-size">Vendas Por Bar</span></button>
                </Link>
            </p>
        </div>
  )
}
