import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../services/api";
import { useParams } from "react-router-dom";

const PromocaoNova = () => {
  const [name, setName] = useState("");
  const [item, setItem] = useState({});
  const [quantidade, setQuantidade] = useState(0);
  const [inicio, setInicio] = useState(new Date());
  const [fim, setFim] = useState(new Date());
  const [valor, setValor] = useState(0);
  const params = useParams();
  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    try {
      const response = await api.get(`bar/${params.id}`);
      setItem({...response.data, id: params.id});
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name,
      item,
      quantidade,
      inicio,
      fim,
      valor,
    };
    try {
      await api.post("promocoes", formData);
      alert("Criada com sucesso");
      window.location = "/promocoes";
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <div>
      <h1>Promoção para</h1>
      <div>
        <h3>{item.title}</h3>
        Valor unitário: <b>R$ {item.price}</b>
      </div>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nome da Promoção</label>
          <input
            type="text"
            className="form-control"
            id="promo_nome"
            onChange={(e) => setName(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <label>Quantas compras para acionar promoção?</label>
          <input
            type="number"
            className="form-control"
            id="promo_quantidade"
            onChange={(e) => setQuantidade(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <label>Adicionar quantos créditos ao usuário?</label>
          <input
            type="number"
            className="form-control"
            id="promo_valor"
            onChange={(e) => setValor(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <label>Promoção válida a partir de:</label>
          <DatePicker
            id="promoção_a_partir"
            selected={inicio}
            onChange={(date) => setInicio(date)}
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeInput
          />
        </div>
        <div className="form-group">
          <label>Promoção válida até:</label>
          <DatePicker
            id="promoção_valida_até"
            selected={fim}
            onChange={(date) => setFim(date)}
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeInput
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Criar
        </button>
      </form>
    </div>
  );
};

PromocaoNova.propTypes = {
  match: PropTypes.object,
};

export default PromocaoNova;
