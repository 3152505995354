import React, { useEffect, useState, memo } from 'react'
import { getNumberOfPendingActions } from '../../services/users'
import Loading from '../Loading'

export default memo(function PendingActionsNumber() {
  const [nAcoes, setNAcoes] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)

  async function getNumberOfPA() {
    getNumberOfPendingActions().then((e) => setNAcoes(e))
  }

  useEffect(() => {
    getNumberOfPA().then(() => setIsLoaded(true))
    // eslint-disable-next-line
  }, [])

  if (!isLoaded) {
    return <Loading size={40} />
  }

  return (
    <div>
      <h3>Ações pendentes</h3>
      {nAcoes > 0 ? (
        <>
          Existe um total de <strong>{nAcoes}</strong> pendentes, quando puder dá um toque no administrador pra ele
          aprovar ou reprovar
        </>
      ) : (
        <>Não há ações pendentes</>
      )}
    </div>
  )
})
