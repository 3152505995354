import React, { useState, useEffect } from 'react'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import api from '../services/api'

import { useParams } from 'react-router-dom'
import Modal from '../components/Modal'
import '../containers/Entrada.css'

export default function EntradaCriaCadastro () {
  const [modal, setModal] = useState({})
  const [pagamento, setPagamento] = useState('')
  const [nome, setNome] = useState('')
  const [creditos, setCreditos] = useState(0)
  const [entrada, setEntrada] = useState(0)
  const [tipo, setTipo] = useState('')
  const [dn, setDn] = useState('')
  const [doc, setDoc] = useState('')
  const [email, setEmail] = useState('')
  const params = useParams()

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad () {
    if (!/^\d+$/.test(params.campo)) {
      window.location = '/'
    }
    setCreditos(params.creditos)
    setEntrada(params.entrada)
    setDoc(params.doc)
    params.dn ? setDn(atob(params.dn)) : setDn('')
    if (isValidCPF(params.campo)) {
      setTipo('CPF:')
    } else {
      setTipo('Telefone:')
    }
  }

  function validarDataNascimento (campo) {
    if (campo === '') {
      return true
    }
    const cut = campo.split('/')
    const dataNascimento = new Date(cut[2] + '-' + cut[1] + '-' + cut[0])
    // Verificar se o valor inserido é uma data válida
    if (isNaN(dataNascimento)) {
      return false
    }

    // Verificar se a data é posterior à data atual
    const dataAtual = new Date()
    if (dataNascimento > dataAtual) {
      return false
    }

    // Verificar se o ano de nascimento é válido (entre 1900 e o ano atual)
    const anoNascimento = dataNascimento.getFullYear()
    const anoAtual = dataAtual.getFullYear()
    if (anoNascimento < 1900 || anoNascimento > anoAtual) {
      return false
    }

    return true
  }

  const handleDataChange = (event) => {
    const { value } = event.target
    const formattedDate = formatarData(value)
    setDn(formattedDate)
  }

  const formatarData = (dataDigitada) => {
    let formattedDate = dataDigitada.replace(/\D/g, '')

    if (formattedDate.length > 2) {
      formattedDate = `${formattedDate.slice(0, 2)}/${formattedDate.slice(2)}`
    }

    if (formattedDate.length > 5) {
      formattedDate = `${formattedDate.slice(0, 5)}/${formattedDate.slice(5, 9)}`
    }

    return formattedDate
  }

  function isValidCPF (cpf) {
    if (typeof cpf !== 'string') return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
      !cpf ||
            cpf.length !== 11 ||
            cpf === '00000000000' ||
            cpf === '11111111111' ||
            cpf === '22222222222' ||
            cpf === '33333333333' ||
            cpf === '44444444444' ||
            cpf === '55555555555' ||
            cpf === '66666666666' ||
            cpf === '77777777777' ||
            cpf === '88888888888' ||
            cpf === '99999999999'
    ) {
      return false
    }
    let soma = 0
    let resto
    for (let i = 1; i <= 9; i++) { soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i) }
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (let i = 1; i <= 10; i++) { soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i) }
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(10, 11))) return false
    return true
  }

  async function handleSubmit (event) {
    event.preventDefault()

    if (!validarDataNascimento(dn)) {
      alert('Arrumar data de nascimento')
      return false
    }

    const cEmail = email !== '' ? email : new Date().getTime() + '@ragazzid.fake'
    const nomecompleto = nome.split(' ')
    const primeiro = nomecompleto.shift()
    const sobrenome = nomecompleto.join(' ')
    const tel = params.campo

    const cut = dn ? dn.split('/') : undefined
    let dataNascimento
    if (cut) {
      dataNascimento = new Date(cut[2] + '-' + cut[1] + '-' + cut[0])
    }

    try {
      const formData = {
        name: primeiro,
        lastname: sobrenome,
        email: cEmail,
        rg: (doc && doc !== '00000000000') ? doc : '' + new Date().getTime(),
        cpf: (doc && doc !== '00000000000') ? doc : '' + new Date().getTime(),
        birth: dataNascimento ? new Date(dataNascimento) : new Date(),
        tel,
        pass: new Date()
      }

      if (parseInt(creditos) > 100) {
        alert('O crédito não pode ser maior que R$ 100,00')
        alert('Operação não realizada, tente novamente')
        return 0
      }
      setModal({
        show: true,
        type: 'warning',
        title: 'Processando',
        msg: 'Aguarde...'
      })

      try {
        const res = await api.post('users', formData)
        await api.post(
          'users/' + res.data.id + '/comanda', { comanda: params.comanda, creditos: parseInt(creditos), pagamento }
        )

        if (parseInt(entrada, 10) > 0) {
          await api.post('/entrada/pagamento', {
            comanda: params.comanda,
            entrada: parseInt(entrada, 10),
            tel: res.data.tel
          })
        }

        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: `Usuário ${nome} foi associado a comanda ${params.comanda}`,
          link: '/entrada'
        })
      } catch (e) {
        // setModal({
        //   show: true,
        //   type: 'danger',
        //   title: 'Erro',
        //   msg: JSON.stringify(e.response.data.message),
        //   link: '/entrada'
        // })
        setModal(prev => ({ ...prev, show: false }))
        alert(JSON.stringify(e.response.data.message))
      }
    } catch (e) {
      alert('Erro ao criar o cadastro:\n\n' + e.response.data.message)
      window.location = '/'
    }
  }

  function validateForm () {
    return nome.split(' ').length > 1 && pagamento.length > 0
  }

  return (
        <div>
            <Modal modal={modal} />
            <h1>Cadastrar Usuário - Entrada</h1>
            <div className="alert alert-warning">
                Não encontrei correspondência para <b>{tipo}</b> {params.campo}<br></br>
            </div>
            <p>Deseja criar o usuário e já associar a comanda?</p>
            <p>Para continuar, preencha o nome abaixo e clique em cadastrar</p>
            <Form onSubmit={handleSubmit}>
                <Form.Group size="lg">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                autoFocus
                type="text"
                id="nome"
                value={nome}
                className={ nome.split(' ').length > 1 ? 'is-valid' : 'is-invalid'}
                onChange={(e) => setNome(e.target.value)}
                />

<Form.Label>Data de Nascimento</Form.Label>
<input
            id="inicio"
            value={dn}
            onChange={handleDataChange}
            className={ validarDataNascimento(dn) ? 'is-valid form-control' : 'is-invalid form-control'}
          />

<Form.Label>Documento (CPF / RG)</Form.Label>
                <Form.Control
                className={ doc.length === 11 ? 'is-valid form-control' : 'is-invalid form-control'}
                type="text"
                id="nome"
                value={doc}
                onChange={(e) => setDoc(e.target.value)}
                />

<Form.Group
          size="lg"
          controlId="email"
        >
        <Form.Label className="my-2"> Email </Form.Label>
          <Form.Control
            type="text"
            value={email}
            className={ email.length > 5 && email.split('@').length > 1 ? 'is-valid form-control' : 'is-invalid form-control'}
            onChange={
            (e) => setEmail(e.target.value)
        }
          />
        </Form.Group>

<Form.Label>Entrada</Form.Label>
                <Form.Control
                className={ entrada >= 0 ? 'is-valid form-control' : 'is-invalid form-control'}
                id="entrada"
                type="number"
                value={entrada}
                onChange={(e) => setCreditos(e.target.value)}
                />
                </Form.Group>
     <Form.Group>
      <Form.Label>Creditos</Form.Label>
                      <Form.Control
                      autoFocus
                      id="creditos"
                      type="number"
                      value={creditos}
                      className={ creditos >= 0 ? 'is-valid form-control' : 'is-invalid form-control'}
                      onChange={(e) => setCreditos(e.target.value)}
                      />

              </Form.Group>

                <div className="container my-3">
            <div className="row" onChange={event => setPagamento(event.target.value)}>
                <div className="col">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="pagamento" id="cbxDinheiro" value="dinheiro"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Dinheiro
                    </label>
                </div>
                </div>
                <div className="col">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="pagamento" id="cbxPix" value="pix"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Pix
                    </label>
                </div>
                </div>
                <div className="col">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="pagamento" id="cbxCartao" value="cartao"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                        Cartão
                    </label>
                </div>
                </div>
            </div>
        </div>

                <Button
                block
                size="lg"
                type="submit"
                // isLoading={isLoading}
                disabled={!validateForm()}
                id="btnCadastrar"
                >
                Cadastrar
                </Button>
                <hr />
                <button

                    className="btn-lg btn btn-success" type="submit"
                    onClick={() => { window.location = '/' }}
                >
                    Voltar
                </button>
            </Form>
        </div>
  )
}
