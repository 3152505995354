import React, { useEffect, useState } from 'react'
import './AutoComplete.css'
import './../containers/Entrada.css'
import InputMask from 'react-input-mask'
import { telefone } from '../helpers/Formata'
import api from '../services/api'
import { cpf } from 'cpf-cnpj-validator'

export default function AutoComplete({ query, filled, setQuery, setUserFound, isGringo, id }) {
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [text, setText] = useState('')

  async function loadSuggestions() {
    setQuery(text.replace(/\D/g, ''))
    if (text.length > 5) {
      const search = await api.get(`users/get/${text}`)
      if (search.data.length > 0) {
        setFilteredSuggestions(search.data)
      } else {
        setFilteredSuggestions([])
      }
    } else {
      setFilteredSuggestions([])
    }
  }

  useEffect(() => {
    loadSuggestions().then(() => {
      setActiveSuggestionIndex(0)
      setShowSuggestions(true)
    })
  }, [text])

  useEffect(() => {
    toogleShow()
  }, [filled])

  function toogleShow() {
    if (showSuggestions) {
      setShowSuggestions(false)
    }
  }

  const onClick = (e) => {
    const cpf = e.target.id.split('-')[1]
    const user = filteredSuggestions.find((e) => e.cpf === cpf)
    setUserFound(user)

    setQuery(user.cpf)
    setShowSuggestions(false)
    setFilteredSuggestions([])
  }

  function SuggestionsListComponent() {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className
          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = 'suggestion-active'
          }
          return (
            <li id={`s-${suggestion.cpf}`} className={className} key={suggestion.cpf} onClick={onClick}>
              {`${telefone(suggestion.tel)} @ ${suggestion.name} ${suggestion.lastname} @ 
              ${cpf.isValid(suggestion.cpf) ? cpf.format(suggestion.cpf) : suggestion.cpf} @ 
              ${suggestion.birth ? new Date(suggestion.birth).toLocaleDateString('pt-BR') : ''}`}{' '}
              @ Vacina OK
            </li>
          )
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <em>Não encontrei, usuário novo!</em>
      </div>
    )
  }
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setQuery(filteredSuggestions[activeSuggestionIndex])
      setFilteredSuggestions([])
    }
  }

  function validateGringo() {
    return isGringo ? true : query.length === 11
  }

  return (
    <>
      <InputMask
        type="number"
        className={validateGringo() ? 'is-valid form-control' : 'is-invalid form-control'}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={onKeyDown}
        value={query}
        maxLength={11}
        id={id}
      />
      {showSuggestions && text && <SuggestionsListComponent />}
    </>
  )
}
