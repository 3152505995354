import React from 'react'
import PropTypes from 'prop-types'

ListaUserPromo.propTypes = {
  promos: PropTypes.array,
  user_id: PropTypes.string
}

export default function ListaUserPromo ({ promos, user_id }) {
  return (
    <div>
      { promos.map((x) => (
        <div key={x.promo_id}>
          <div className="card-body m-4">
            <h5 className="card-title">{x.promo_name}</h5>
            <p className="card-text">
              {x.comprados}
              {' '}
              /
              {x.promo_quantidade}
            </p>
            <a id={x.promo_name} href={`${(x.comprados >= x.promo_quantidade) ? '/' : '#'}promocao/entregar/${user_id}/${x.promo_id}`} className={`btn ${(x.comprados >= x.promo_quantidade) ? 'btn-success' : 'btn-dark'}`}>Entregar</a>
          </div>
        </div>
      ))}
    </div>
  )
}
