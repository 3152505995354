import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import LoaderButton from './LoaderButton'
import Modal from './Modal'
import EventDate from './Events/EventDate'
import api from '../services/api'
import { getDeltaHour } from '../helpers/Datas'

export default function EventoNovo() {
  const params = useParams()
  const [loading, setLoading] = useState(true)

  const [nome, setNome] = useState('')
  const [eventStart, setEventStart] = useState()
  const [eventEnd, setEventEnd] = useState()
  const [limitePessoas, setLimitePessoas] = useState('')
  const [valorConsuma, setValorConsuma] = useState('')
  const [valorEntrada, setValorEntrada] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({})

  function validateForm() {
    if (eventStart !== null && eventEnd !== null) {
      const totalHours = getDeltaHour(eventStart, eventEnd)
      return totalHours != 0 && nome !== ''
    } else {
      return false
    }
  }
  useEffect(() => {
    onLoad()
  }, [params])

  async function onLoad() {
    if (params.id !== undefined && nome === '') {
      const ev = await api.get(`events/${params.id}`)
      setNome(ev.data.name)
      setLimitePessoas(ev.data.limite_pessoas)
      setValorConsuma(ev.data.valor_consuma)
      setValorEntrada(ev.data.valor_entrada)
      setEventEnd(ev.data.fim)
      setEventStart(ev.data.inicio)
      setLoading(false)
    }
    setLoading(false)
  }

  async function novoEvento(e) {
    const eventStartDate = typeof eventStart === 'string' ? new Date(eventStart) : eventStart
    const eventEndDate = typeof eventEnd === 'string' ? new Date(eventEnd) : eventEnd
    const totalHours = getDeltaHour(eventStartDate, eventEndDate)
    if (totalHours < 4) {
      alert('O evento precisa durar mais que 4 horas')
      return false
    }
    e.preventDefault()
    setIsLoading(true)

    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando criar o novo evento',
      msg: 'Aguarde...'
    })

    try {
      const formData = {
        name: nome,
        inicio: eventStart,
        fim: eventEnd,
        limite_pessoas: limitePessoas,
        valor_entrada: valorEntrada,
        valor_consuma: valorConsuma
      }
      if (params.id !== undefined) {
        await api.put(`/events/${params.id}`, formData)
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Deu tudo certo!',
          link: `/eventos/${params.id}`,
          refresh: true
        })
      } else {
        await api.post('/events', formData)
        eventStart
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Deu tudo certo!',
          link: '/',
          refresh: true
        })
      }
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: e.response.data.message,
        link: '/'
      })
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  if (loading) {
    return <p>Carregando...</p>
  }

  return (
    <div>
      <Modal modal={modal} />
      <h2>Novo Evento</h2>
      <form>
        <div className="form-greventStartoup my-3">
          <label htmlFor="produto">Nome do Evento</label>
          <input
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            type="text"
            className={nome !== '' ? 'is-valid form-control' : 'is-invalid form-control'}
            id="nomeEvento"
            placeholder="Relaxxxo"
          />
        </div>
        <div className="form-group my-3">
          <label htmlFor="price">Valor Consumação</label>
          <input
            value={valorConsuma}
            type="number"
            className={valorConsuma > 0 ? 'is-valid form-control' : 'is-invalid form-control'}
            id="valorConsumacao"
            onChange={(e) => setValorConsuma(e.target.value)}
            placeholder=""
          />
        </div>

        <div className="form-group my-3">
          <label htmlFor="price">Valor Entrada (seca)</label>
          <input
            value={valorEntrada}
            type="number"
            className={valorEntrada > 0 ? 'is-valid form-control' : 'is-invalid form-control'}
            id="valorEntrada"
            onChange={(e) => setValorEntrada(e.target.value)}
            placeholder=""
          />
        </div>
        <div className="form-group my-3">
          <label htmlFor="estoque">Limite de Pessoas</label>
          <input
            value={limitePessoas}
            type="number"
            onChange={(e) => setLimitePessoas(e.target.value)}
            className={limitePessoas > 0 ? 'is-valid form-control' : 'is-invalid form-control'}
            id="limitePessoas"
            placeholder=""
          />
        </div>
        <div className="card">
          <div className="card-body">Lembre que o evento deve durar pelo menos 4 horas</div>
        </div>

        <div className="form-group">
          <p className="my-2">
            <label htmlFor="inicio">Inicio</label>
          </p>

          <div className="">
            <div className="">
              <EventDate setDateTime={setEventStart} initialDate={eventStart} idName={'inicio'} />
            </div>
          </div>
        </div>

        <div className="form-group">
          <p className="my-2">
            <label htmlFor="inicio">Inicio</label>
          </p>

          <div className="">
            <div className="">
              <EventDate setDateTime={setEventEnd} initialDate={eventEnd} idName={'fim'} />
            </div>
          </div>
        </div>

        <LoaderButton
          id="criar"
          isLoading={isLoading}
          disabled={!validateForm()}
          type="submit"
          onClick={novoEvento}
          className="btn btn-primary my-5"
        >
          {params.id !== undefined ? 'Editar' : 'Criar'}
        </LoaderButton>
      </form>
    </div>
  )
}
