import React, { useEffect, useState } from 'react'
import api from '../services/api'
import { telefone } from '../helpers/Formata'
export default function Bloqueados () {
  const [usersBloqueados, setUsersBloqueados] = useState([])
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    onLoad()
    async function onLoad () {
      const res = await api.get('/users/listBlockedUsers')
      setUsersBloqueados(res.data)
      setLoaded(true)
    }
  }, [loaded])

  return (
        <div>
            <h1>Lista de Usuários Bloqueados</h1>
            { loaded
              ? (
                <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Bloqueado em</th>
                    <th scope="col">Motivo</th>
                    <th scope="col">Até</th>
                </tr>
                </thead>
                <tbody>

                { usersBloqueados.length
                  ? (
                    <></>
                    )
                  : (
                    <>Nao tenho nada pra mostrar :( - Bloqueie alguém, rs</>
                    )}

                { usersBloqueados.map((u) => (
                    <tr key={u.id}>
                        <th scope="row">{u.name} {u.lastname}</th>
                        <td>{telefone(u.tel)}</td>
                        <td>{u.blocked ? new Date(u.blocked.when).toLocaleDateString('BR') : ''}</td>
                        <td>{u.blocked.reason}</td>
                        <td>{new Date(u.blocked.until).toLocaleDateString('pt-BR')}</td>
                    </tr>
                ))}
                </tbody>
            </table>
                )
              : (
                <>Carregando...</>
                )}

        </div>
  )
}
