import React, { useState, useEffect } from 'react'
import { getHistoryLogs } from '../services/logs'
import UserActivity from '../components/Log/UserActivity'

export default function Logs() {
  const [history, setHistory] = useState([])

  useEffect(() => {
    getHistoryLogs().then((h) => {
      setHistory(h)
    })
  }, [])
  return (
    <div>
      <h1>Logs</h1>
      {history.length > 0 ? (
        <div>
          <UserActivity activities={history} />
        </div>
      ) : (
        <>Nada para ver aqui :)</>
      )}
    </div>
  )
}
