import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

const EventDate = ({ setDateTime, initialDate, idName }) => {
  const [startDate, setStartDate] = useState(initialDate ? new Date(initialDate) : new Date())
  const [startTime, setStartTime] = useState(initialDate ? new Date(initialDate) : new Date())

  const handleDateChange = (date) => {
    setStartDate(date)
  }

  const handleTimeChange = (time) => {
    setStartTime(time)
  }

  const getDateTime = () => {
    const date = moment(startDate).format('YYYY-MM-DD')
    const time = moment(startTime).format('HH:mm')
    return new Date(`${date}T${time}`)
  }

  useEffect(() => {
    setDateTime(getDateTime())
  }, [startDate, startTime])

  return (
    <div>
      <div>
        <DatePicker
          selected={startDate}
          className={startDate != '' ? 'is-valid form-control' : 'is-invalid form-control'}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          id={`${idName}Data`}
        />
      </div>
      <div className="my-2">
        <DatePicker
          selected={startTime}
          onChange={handleTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Hora"
          dateFormat="HH:mm"
          className={startTime != '' ? 'is-valid form-control' : 'is-invalid form-control'}
          id={`${idName}Horario`}
        />
      </div>
    </div>
  )
}

export default EventDate
