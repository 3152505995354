import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import api from '../services/api'
import { getUserComanda } from '../helpers/Users'
import Modal from '../components/Modal'

export default function DesfazerVenda () {
  const params = useParams()
  const [modal, setModal] = useState({})

  async function desfazer() {
    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando desfazer a venda',
      msg: 'Aguarde...'
    })
    try {
      await api.post(`users/${params.user_id}/item/${params.creditUsageId}`, {
        id: params.user_id,
        creditUsageId: params.creditUsageId
      })
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Deu tudo certo!',
        link: '/bar'
      })
    } catch (e) {
      console.log(e.response)
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.message),
        link: '/'
      })
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    try {
      const res = await api.get(`users/${params.user_id}`)
      const user = res.data
      const item = user.credit_usage.find((x) => x._id === params.creditUsageId)

      if (item) {
        const alvo = document.getElementById('target')
        const html = `
            <div class="text-center">
            <h2>Detalhes do Item</h2>
            <h3>${item.title}</h3>
            <p>Quantidade: ${item.quantity}</p>
            <h3>Valor: ${item.itemTotal}</h3>
            Lembre que o valor total será adicionado de volta a comanda
            <h2>-- ${getUserComanda(user.comanda)} --</h2>
            <hr/>
            `

        alvo.innerHTML = html
      }
    } catch (e) {
      console.log(e.response)
    }
  }
  return (
        <div className="Venda">
            <Modal modal={modal} />
            <h1>Desfazendo venda</h1>
            <div className="lander">
                <p className="text-muted">Tem certeja que deseja cancelar esse produto?</p>
                <div id="target"></div>
            </div>
            <div className="text-center">
                <Link to="/bar">
                    <button className="btn btn-success btn-lg bt mx-1">Voltar</button>
                </Link>
                | <button onClick={desfazer} className="btn btn-danger btn-lg bt">Sim</button>
            </div>
        </div>
  )
}
