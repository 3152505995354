import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'
import LoaderButton from '../components/LoaderButton'
import CaixaBalanco from '../components/CaixaBalanco'
import CaixaRelatorioBasico from '../components/CaixaRelatorioBasico'
import Modal from '../components/Modal'

export default function CaixaAdicionar () {
  const [caixa, setCaixa] = useState({ entradas: [], saidas: [] })
  const [adicionar, setAdicionar] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const [modal, setModal] = useState({})

  useEffect(() => {
    getCaixa(params.id)
    // eslint-disable-next-line
  }, [])

  async function getCaixa (id) {
    const res = await api.get('caixa/' + id)
    setCaixa(res.data)
  }

  async function handleSubmit () {
    setIsLoading(true)
    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando adicionar dinheiro',
      msg: 'Aguarde...'
    })
    try {
      await api.post('/caixa/adicionar', { id: params.id, valor: parseInt(adicionar), pagamento: 'dinheiro' })
      setModal({
        show: true,
        type: "success",
        title: "Sucesso",
        msg: "Dinheiro adicionado",
        link: "/caixaAdm",
      });
    } catch (e) {
      setIsLoading(false)
      setModal({
        show: true,
        type: "danger",
        title: "Erro",
        msg: JSON.stringify(e.message),
        link: "/caixaAdm",
      });
    }
    setIsLoading(false)
  }
  return (
    <div>
      <Modal modal={modal} />
      <h1>Adicionar Dinheiro</h1>
      Valor
      <input className="form-control my-2" type="number" onChange={(e) => setAdicionar(e.target.value) } id="intRetirada" />
      <LoaderButton isLoading={isLoading} onClick={handleSubmit} className="btn btn-success" >Adicionar Dinheiro</LoaderButton>
      <hr />

      <CaixaRelatorioBasico caixa={caixa} />
      <hr />
      Total em caixa: R$: {caixa.saldo + caixa.cartao},00
      <hr />
      <CaixaBalanco caixa={caixa} />
    </div>
  )
}
