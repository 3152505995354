import React, { useState, useEffect } from 'react'
import EntryStatus from '../components/Events/EntryStatus'
import PendingActionsNumber from '../components/Users/PendingActionsNumber'
import EntranceForm from '../components/Entrance/EntranceForm'
import EventStatus from '../components/Events/EventStatus'
import Loading from '../components/Loading'
import { getCurrentEvent } from '../services/events'
// import Loading from '../components/Loading'

export default function Portaria() {
  const [isFetchingEvents, setIsFecthingEvents] = useState(true)
  const [ev, setEv] = useState({})

  function fixMeLater() {
    return true
  }

  useEffect(() => {
    getCurrentEvent().then((e) => {
      if (e.length) {
        setEv(e[0])
      }
      setIsFecthingEvents(false)
    })
  }, [])

  if (isFetchingEvents) {
    return (
      <div>
        <h2>Veficando se há eventos</h2>
        <Loading size={50} />
      </div>
    )
  }
  if (!ev.name) {
    return (
      <div>
        <h2>Desculpe</h2>
        <p>Não há eventos agora, fale com o ADM</p>
      </div>
    )
  }

  return (
    <>
      <h1>Portaria</h1>
      <>
        <EntranceForm />
        <EventStatus setRunningEvent={fixMeLater} />
        <EntryStatus />
        <PendingActionsNumber />
      </>
    </>
  )
}
