import React, { useState, useEffect } from 'react'
import api from '../services/api'

export default function Feed () {
  const [selectedUser, setSelectedUser] = useState('')
  const [users, setUsers] = useState([])

  useEffect(() => {
    getFeed()
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    getUsers()

    async function getUsers () {
      const res = await api.get('users/admin')
      setUsers(res.data)
    }
  }, [])

  async function getFeed () {
    console.log(selectedUser)
    const tg = document.getElementById('target')
    let r = ''
    if (selectedUser === '') {
      r = await api.get('feed')
    } else {
      r = await api.get('feed/user/' + selectedUser)
    }

    let res = ''
    r.data.forEach((r) => {
      res += `<tr>
                <td>${new Date(r.created).toLocaleString('pt-BR')}</td>
                <td>${r.msg}</td>
                </tr>
            `
    })

    tg.innerHTML = `
        <table class="table table-striped">
        <thead>
            <tr>
            <th scope="col">Horário</th>
            <th scope="col">O que aconteceu?</th>
            </tr>
        </thead>
        <tbody>
        ${res}
            
        </tbody>
        </table>
            
        `
  }
  return (
        <div>
            <h1>Feed</h1>
            <button onClick={getFeed} alt="refresh">Atualizar</button>
            <select onChange={(e) => setSelectedUser(e.target.value)} className="my-2 form-control">
                <option value="">Selecione</option>
                <option value="">----</option>
                { users.map((r) => (
                    <option key={r.id} value={r.id}>{r.name} {r.lastname}</option>
                ))}
            </select>
            <div id="target">

            </div>
        </div>
  )
}
