// import React, { useEffect } from 'react'
// import api from '../services/api'
// import { pedidosTrabalhando, pedidosAguardando } from '../views/pedidos'

// export default function MeuTrabalho () {
//   useEffect(() => {
//     getPeditosTrabalhando()
//     getPedidosPendentes()
//     window.setInterval(function () {
//       getPedidosPendentes()
//       getPeditosTrabalhando()
//     }, 5000)
//   })

//   async function getPeditosTrabalhando () {
//     const trabalhando = await api.get('pedidos/trabalhando')
//     const trabalhando_div = document.getElementById('trabalhando')
//     trabalhando_div.innerHTML = pedidosTrabalhando(trabalhando.data)
//   }

//   async function getPedidosPendentes () {
//     const pendentes = await api.get('pedidos/abertos')
//     const novos = document.getElementById('novos')
//     if (novos) {
//       novos.innerHTML = pedidosAguardando(pendentes.data)
//     }
//     // var linhas = ''
//     // pendentes.data.forEach((v) => {
//     //     linhas += `
//     //         <tr>
//     //         <th scope="row">${timeSince(new Date(v.entrada_em))}</th>
//     //         <td>${v.user.comanda}</td>
//     //         <td>${v.user.name}</td>
//     //         <td>${v.item.title}</td>
//     //         <td>${v.item.quantity}</td>
//     //         <td>
//     //             <a href="/pedidos/status/${v.id}/pegar" class="btn btn-warning">Pegar</a>
//     //         </td>
//     //         </tr>
//     //     `
//     // })
//     // novos.innerHTML = `
//     // <table class="table">
//     // <thead>
//     //     <tr>
//     //     <th scope="col">Feito a</th>
//     //     <th scope="col">Comanda</th>
//     //     <th scope="col">Nome</th>
//     //     <th scope="col">Produto</th>
//     //     <th scope="col">Quantidade</th>
//     //     <th scope="col">Ação</th>
//     //     </tr>
//     // </thead>
//     // <tbody>
//     //     ${linhas}
//     // </tbody>
//     // </table>
//     // `
//   }
//   return (
//         <div>
//             <h1>Pedidos em Andamento</h1>
//             <div id="trabalhando">

//             </div>
//             <hr></hr>
//             <h1>Pedidos Aguardando</h1>
//             <div id="novos"></div>
//         </div>
//   )
// }

import React from "react";

export default function MeuTrabalho() {
  return <div>MeuTrabalho</div>;
}
