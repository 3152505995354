import React from 'react'
import PropTypes from 'prop-types'
import { dinheiro } from '../helpers/Formata'
import { getUserComanda } from '../helpers/Users'

ListItemsReport.propTypes = {
  items: PropTypes.array
}

export function ListItemsReport ({ items }) {
  return (
    <ul className="list-group">
        { items.map((e) => (
                        <li className="list-group-item" key={e._id}>
                            {e._id} | R$ { dinheiro(e.total) }
                        </li>
        ))}
    </ul>
  )
}

ListItemsReportUsers.propTypes = {
  items: PropTypes.array
}

export function ListItemsReportUsers ({ items }) {
  console.log(items)
  return (
    <ul className="list-group list-group-flush">
        { items.map((e, k) => (
                        <li className="list-group-item" key={e.id}>
                          {k}  | {e.name + ' ' + e.lastname} - { getUserComanda(e.comanda) }
                        </li>
        ))}
    </ul>
  )
}
