import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import api from '../services/api'
import ModalSucesso from '../components/ModalSucesso'

export default function PromocaoEntregar () {
  const params = useParams()

  const [modal, setModal] = useState(false);
  const [msg, setMsg] = useState('')
  const [titulo, setTitulo] = useState('')
  const [tipo, setTipo] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function onLoad () {
      try {
        setModal(true)
        setTitulo('Trabalhando')
        setLoading(true)
        setTipo('modal-warning')
        setMsg('Trabalhando na sua operação, aguarde ...')

        await api.post(`users/promo/${params.user_id}/${params.promo_id}`)

        setTitulo('Certinho!')
        setLoading(false)
        setTipo('modal-success')
        setMsg("Deu certo, entregue a promoção, por favor!");
      } catch (e) {
        setModal(true)
        setTitulo('ERRO :( Falhamos')
        setLoading(false)
        setTipo('modal-danger')
        setMsg(`Operação Falhou: ${e.message}`);
      }
    }
    onLoad()
  }, [params])

  return (
    <div className="text-center">
      <ModalSucesso
        loading={loading}
        tipo={tipo}
        titulo={titulo}
        msg={msg}
        link={() => (window.location = "/bar")}
        show={modal}
      />
    </div>
  );
}
