import React from 'react'

import PropTypes from 'prop-types'

CaixaDetalhesHistorico.propTypes = {
  u: PropTypes.array
}

export function CaixaDetalhesHistorico ({
  u
}) {
  function toggleDiv (id) {
    const div = document.getElementById(id)
    if (div.style.display === 'none') {
      div.style.display = 'block'
    } else {
      div.style.display = 'none'
    }
  }
  return (
    <div>
      {[...u].reverse().map((u) => (
        <div key={u.closedAt} className="container">
          <h5>
            {new Date(u.closedAt).toLocaleDateString('br')}
            {' '}
            -
            {' '}
            {u.adminFullName}
          </h5>
          <div className="row">
            <div className="col">
              Dinheiro Sistema
            </div>
            <div className="col">
              R$
              {' '}
              {u.entrada}
              ,00
            </div>
          </div>
          <div className="row">
            <div onClick={() => toggleDiv('entradas')} className="col">
              Cartão Sistema +
            </div>
            <div className="col">
              R$
              {' '}
              {u.cartao}
              ,00
            </div>
          </div>
          <div className="row">
            <div onClick={() => toggleDiv('entradas')} className="col">
              Cartão Informado +
            </div>
            <div className="col">
              R$
              {' '}
              {u.fechamentoCartao}
              ,00
            </div>
          </div>
          <div className="row">
            <div onClick={() => toggleDiv('entradas')} className="col">
              Din + Cartão (Inf) +
            </div>
            <div className="col">
              R$
              {' '}
              {u.cartao + u.entrada}
              ,00
            </div>
          </div>
          <div className="row">
            <div onClick={() => toggleDiv('sangrias')} className="col">
              Sangria +
            </div>
            <div className="col">
              R$
              {' '}
              {u.saida}
              ,00
            </div>
          </div>
          <div className="row">
            <div className="col">
              Fechamento
            </div>
            <div className="col">
              R$
              {' '}
              {u.fechamento}
              ,00
            </div>
          </div>

          <div id="sangrias" style={{ display: 'none' }}>
            <h3>Sangrias</h3>
            <ul>
              { u.saidas.map((e) => (
                <li key={u._id}>
                  { new Date(e.created_at).toLocaleString('br')}
                  {' '}
                  |
                  {' '}
                  {e.adminFullName}
                  {' '}
                  R$
                  {' '}
                  {e.valor}
src/containers/Relatorios.js
                  {' '}
                </li>
              ))}
            </ul>
          </div>

          <div id="entradas" style={{ display: 'none' }}>
            <h3>Entradas</h3>
            <ul>
              { u.entradas.map((e) => (
                <li key={e._id}>
                  { new Date(e.created_at).toLocaleString('br')}
                  {' '}
                  |
                  {' '}
                  {e.adminFullName}
                  {' '}
                  R$
                  {' '}
                  {e.valor}
                  {' '}
                </li>
              ))}
            </ul>
          </div>
          <hr />
        </div>
      ))}
    </div>
  )
}
