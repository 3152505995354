import api from './api'

export const getFav = async () => {
  try {
    const res = await api.get('bar/favorites')
    if (res.status === 200) {
      return res.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const setFav = async (products) => {
  try {
    const res = await api.post('bar/favorites', products)
    if (res.status === 200) {
      return res.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const addFav = async (product) => {
  try {
    const res = await api.post('bar/favorites/add', product)
    if (res.status === 200) {
      return res.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}


export const removeFav = async (id) => {
  try {
    const res = await api.delete('bar/favorites/add', id)
    if (res.status === 200) {
      return res.data
    }
  } catch (e) {
    alert(e.response.data.message)
  }
}

