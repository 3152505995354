import React from 'react'

import './ModalSucesso.css'
import { BsArrowRepeat } from 'react-icons/bs'
import { getFeatures } from "../services/auth";

export default function ModalSucesso({
  tipo,
  titulo,
  msg,
  link,
  show,
  loading,
  valor,
  comanda,
}) {
  return (
    <>
      {show ? (
        <div className={tipo}>
          <div className="modal-content-ragazzid text-center">
            <h1>{titulo}!</h1>
            <p>{msg}</p>
            {valor >= 0 ? (
              <div>
                <h3>Saldo</h3>
                <h2 className="valorCor">
                  R$
                  {valor}
                  ,00
                </h2>
                <h4>Comanda {comanda}</h4>
              </div>
            ) : (
              <div>
                <h3>Saldo</h3>
                <h2 className="valorCor">R$ {valor},00</h2>
                <h4>Comanda {comanda}</h4>
              </div>
            )}
            {valor < 12 && !getFeatures().includes("PosPago") ? (
              <h2 className="text-danger">
                {valor >= 1 ? <>Saldo quase acabando</> : <>Sem saldo</>}
              </h2>
            ) : (
              <></>
            )}
            {!loading && (
              <div className="d-grid gap-2">
                <button
                  id="btnSucesso"
                  onClick={link}
                  className="btn btn-success"
                >
                  Continuar
                </button>
              </div>
            )}

            {loading === true ? (
              <div className="text-center">
                <BsArrowRepeat className="spinning" size={75} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
