import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'
import LoaderButton from '../components/LoaderButton'
import CaixaBalanco from '../components/CaixaBalanco'
import CaixaRelatorioBasico from '../components/CaixaRelatorioBasico'
import Modal from '../components/Modal'

export default function CaixaRemover () {
  const [caixa, setCaixa] = useState({ entradas: [], saidas: [] })
  const [retirada, setRetirada] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const [modal, setModal] = useState({})

  useEffect(() => {
    getCaixa(params.id)
    // eslint-disable-next-line
  }, [])

  async function getCaixa (id) {
    const res = await api.get('caixa/' + id)
    setCaixa(res.data)
  }

  async function handleSubmit () {
    setIsLoading(true)
    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando remover dinheiro',
      msg: 'Aguarde...'
    })
    try {
      await api.post('/caixa/retirada', { id: params.id, valor: parseInt(retirada), pagamento: 'dinheiro' })
      setModal({
        show: true,
        type: "success",
        title: "Sucesso",
        msg: "Dinheiro removido",
        link: "/caixaRemover/" + params.id,
        refresh: true,
      });
    } catch (e) {
      setIsLoading(false)
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.message),
        link: '/'
      })
    }
    setIsLoading(false)
  }
  return (
    <div>
      <Modal modal={modal} />
      <h1>Retirar Dinheiro</h1>
      Valor
      <input className="form-control my-2" type="number" onChange={(e) => setRetirada(e.target.value) } id="intRetirada" />
      <LoaderButton id="btnRetirar" isLoading={isLoading} onClick={handleSubmit} className="btn btn-warning" >Retirar Dinheiro</LoaderButton>
      <hr />
      <h3>{caixa.adminFullName}</h3>

      <hr />
      <CaixaRelatorioBasico caixa={caixa} />
      <hr />
      <CaixaBalanco caixa={caixa} />
    </div>
  )
}
