import React, { useState } from 'react'
import api from '../services/api'
import { getUserCredits } from '../helpers/Users'
import Modal from '../components/Modal'

export default function Saida () {
  const [comanda, setComanda] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showNotFound, setShowNotFound] = useState(false)
  const [modal, setModal] = useState({})

  const validarCodigo = async () => {
    setShowConfirmation(false)
    setShowError(false)
    setShowNotFound(false)
    try {
      const comandaResposta = await api.get(`/users/comanda/${comanda}`)
      //   const sistemaComanda = getUserComanda(comandaResposta.data[0].comanda)
      const sistemaUserCredits = getUserCredits(comandaResposta.data[0].credit)
      if (sistemaUserCredits >= 0) {
        setShowConfirmation(true)
      } else {
        setShowError(true)
      }
    } catch (e) {
      setShowNotFound(true)
      console.log(e)
    }
  }

  async function freeTicket () {
    try {
      setModal({
        show: true,
        type: 'warning',
        title: 'Desassociado com Sucesso',
        msg: 'Trabalhando em sua operação',
        loading: true
      })
      await api.put('/users/release/ticket', { comanda })

      setModal({
        show: true,
        type: 'success',
        title: 'Desassociado com Sucesso',
        msg: 'Operação conclúida com sucesso!',
        link: '/saida'
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Falhamos',
        msg: e.message,
        link: '/saida'
      })
      console.log(e)
    }
  }

  return (
    <div>
        <Modal modal={modal} />
        <h1>Saída</h1>
        <p>Essa página libera uma pessoa para sair</p>
        <div className="container mt-5">
      <h3>Comanda</h3>
      <div className="mb-3 text-center">
        <label htmlFor="codigo" className="form-label">
        </label>
        <input
          type="text"
          className={`form-control ${comanda.length ? 'is-valid' : 'is-invalid'}`}
          id="codigo"
          value={comanda}
          onChange={(e) => setComanda(e.target.value)}
        />
        <button className="mt-5 btn btn-primary" onClick={validarCodigo}>
        Validar Código
      </button>
      </div>
         <div className='text-center'>
            {showConfirmation && (
                <div className="alert alert-success" role="alert">
                <strong>Tudo certo!</strong>
                <p>
                    Essa pessoa está liberada para sair
                </p>
                <button className='btn btn-success' onClick={freeTicket}>Liberar Comanda</button>
            </div>
            )}

            {showError && (
                    <div className="alert alert-danger" role="alert">
                    <strong>Comanda não está zerada!</strong>
                    <p>
                        Solicite ao usuário que vá até um caixa
                    </p>
                </div>
            )}

            {showNotFound && (
                        <div className="alert alert-warning" role="alert">
                        <strong>Não achei!</strong>
                        <p>A comanda digitada não consta no sistema</p>
                    </div>
            )}
        </div>
    </div>
    </div>
  )
}
