import api from "./api";

export async function getCurrentEvent() {
  const response = await api.get(
    `entrada/sympla/eventos?published=true&from=2023-09-13`
  );
  if (response.status === 200) {
    return response.data.data;
  } else {
    return [];
  }
}

export async function getParticipantDataOnEvent(eventId, participant) {
  const response = await api.get(
    `entrada/sympla/event/${eventId}/${participant}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    return [];
  }
}


export async function checkin(eventId, participant, formData) {
  const response = await api.post(
    `entrada/sympla/event/${eventId}/${participant}`,
    formData
  );
  if (response.status === 200) {
    return response.data;
  } else {
    return [];
  }
}
