import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import LoaderButton from './LoaderButton'
import { dinheiro, telefone } from '../helpers/Formata'
import { usoDeHoje } from '../helpers/Datas'

import Modal from './Modal'

import api from '../services/api'

import FilaDePedidos from './FilaDePedidos'
import ListaUserPromo from './ListaUserPromo'
import ListaUserPedidos from './ListaUserPedidos'

import { getUserCredits } from '../helpers/Users'

export default function Comanda ({ setComanda, comanda, setUser }) {
  const [pedidos, setPedidos] = useState([])
  // const [modal, setModal] = useState({})
  const [error, setError] = useState({});

  // const [user, setUser] = useState('')
  const user = { credit_usage: [], promo: [] };
  useEffect(() => {
    getPedidosPendentes();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [limparPedidosLoading, setLimparPedidosLoading] = useState(false);

  async function getPedidosPendentes() {
    const pendentes = await api.get("pedidos/meus");
    setPedidos(pendentes.data);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setError({});
    setIsLoading(true);
    // setModal({
    //   show: true,
    //   type: "warning",
    //   title: "Procurando comanda",
    //   loading: true,
    //   msg: "Aguarde...",
    // });
    // document.getElementById("fila").style.display = "none"
    // document.getElementById("user").style.display = "block"

    try {
      const res = await api.get(`users/comanda/${comanda}`);

      if (res.data.length) {
        setComanda("");
        setUser(res.data[0]);
        // setModal({
        //   show: true,
        //   type: "success",
        //   title: "Sucesso",
        //   msg: "Achei!",
        //   loading: false,
        // });
      } else {
        // modal
        // setModal({
        //   show: true,
        //   type: "danger",
        //   title: "Não achei",
        //   msg: `Comanda ${comanda} não associada a nenhum usuário`,
        //   link: "/bar",
        //   refresh: true,
        //   loading: false,
        // });
        setError({
          message: "Comanda não encontrada, por favor procure de novo",
        });
      }
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 401) {
          window.location = "/";
        }
        setModal({
          show: true,
          type: "danger",
          title: "Não achei",
          msg: `Comanda ${comanda} não associada a nenhum usuário`,
          link: "/bar",
          loading: false,
        });
      } else {
        window.location = "/";
      }
    }
    setIsLoading(false);
    // x.innerHTML = html
  }

  async function limparMeusPedidos() {
    const ctz = window.confirm("Tem certeza?");
    if (!ctz) {
      return null;
    }
    setLimparPedidosLoading(true);
    try {
      await api.post("/pedidos/limparMinhaFila");
      setPedidos([]);
      setLimparPedidosLoading(false);
    } catch (e) {
      console.log(JSON.stringify(e.response.data));
    }
    setLimparPedidosLoading(false);
  }

  function validateForm() {
    return comanda.length > 0;
  }

  return (
    <div className="comanda">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="comanda">
          <Form.Label> Comanda </Form.Label>{' '}
          <Form.Control autoFocus type="number" value={comanda} onChange={(e) => setComanda(e.target.value)} />{' '}
        </Form.Group>{' '}
        <LoaderButton
          id="buscaComanda"
          block
          // size="lg"
          type="submit"
          isLoading={isLoading}
          className="my-2 w-100 btn-hight-40"
          disabled={!validateForm()}
        >
          Procurar
        </LoaderButton>{' '}
      </Form>

      {error.message != undefined && (
        <div className="card bg-warning m-2 p-1">
          <div className="card-header">Problemas</div>
          <div className="card-body">
            <p className="card-text">{error.message}</p>
          </div>
        </div>
      )}
      <div className="target" id="fila">
        <hr />
        <h3>
          Fila de Pedidos |
          <LoaderButton isLoading={limparPedidosLoading} onClick={limparMeusPedidos} className="btn btn-warning m-2">
            Concluir Todos
          </LoaderButton>
        </h3>
        <FilaDePedidos pedidos={pedidos} setPedidos={setPedidos} />
      </div>

      <div id="user" style={{ display: 'none' }}>
        {user ? (
          <>
            <hr />
            <div className="alert alert-success">
              <h3>{user.name}</h3>
              <p>
                Telefone:
                {telefone(user.tel)}
                <br />
              </p>
              <p>
                Crédito Atual:
                <b style={{ color: 'red' }}> R${dinheiro(getUserCredits(user.credit))}</b>
              </p>
              <a href={`bar/${user.id}`} id="listaProdutos" className="btn btn-primary btn-lg">
                Produtos
              </a>
              <hr />
              <h4>Promoções</h4>
              <ListaUserPromo user_id={user.id} promos={user.promo} />
              <hr />
              <h4>Histórico de Consumo</h4>
              <ListaUserPedidos user_id={user.id} pedidos={usoDeHoje(user.credit_usage)} />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
