import React from "react";

import { Link } from "react-router-dom";
import { FiActivity } from "react-icons/fi";
import { dinheiro } from "../helpers/Formata";

import { getRole } from "../services/auth";

import { getByClient } from "../helpers/Users";

export default function ListaUserPedidos({ user, parcial }) {
  if (user === undefined) {
    return <></>;
  }
  return (
    <div>
      {getByClient(user.credit_usage).map((p) => (
        <div
          key={p.usage_id}
          className="row row-main"
          style={{ textDecoration: p.valid ? "" : "line-through" }}
        >
          <div className="col-6">
            <div className="row d-flex">
              <p>
                <b>
                  {p.title} ({p.quantity})
                </b>
              </p>
            </div>
            <div className="row d-flex">
              <p className="text-muted">
                Vendido por {p.admin_fullname.split(" ")[0]}
              </p>
            </div>
            {parcial ? (
              <></>
            ) : (
              <div className="row d-flex">
                <Link
                  to={`${p.valid ? "" : "#"}/bar/refazer/${user.id}/${
                    p._id
                  }`}
                >
                  <button className="btn btn-sm btn-success">Refazer</button>
                </Link>
              </div>
            )}
          </div>
          <div className="col-6">
            <div className="row d-flex justify-content-end">
              <p>
                <b>
                  R$
                  {dinheiro(p.itemTotal)}
                </b>
              </p>
            </div>
            <div className="row d-flex  justify-content-end">
              <p className="text">
                {new Date(p.created_at).toLocaleString("pt-BR")}
              </p>
            </div>
            {parcial ? (
              <></>
            ) : (
              <div className="row d-flex justify-content-end">
                {getRole() !== "Admin" ? (
                  <></>
                ) : (
                  <Link
                    to={`${p.valid ? "" : "#"}/bar/desfazer/${user.id}/${
                      p._id
                    }`}
                  >
                    <button className="btn btn-sm btn-danger">Desfazer</button>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className=" my-4  m text-center">
            <FiActivity />
          </div>
        </div>
      ))}
    </div>
  );
}
