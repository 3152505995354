import api from './api'
import { randomCpf, randomEmail } from '../lib/faker'
import { getName, getClientId } from '../services/auth'

export async function findUserByText(text) {
  const response = await api.get('users/search/' + text)
  if (response.status === 200) {
    return response.data
  }
}

export async function getNumberOfPendingActions() {
  const response = await api.get('users/n/pendingActions')
  if (response.status === 200) {
    return response.data
  }
}

export async function isUserBlocked(query) {
  if (query === undefined) {
    return false
  }
  const user = await api.get('/users/search/' + query.replace(/[^a-zA-Z0-9]/g, ''))
  if (user.data) {
    const blocked = user.data.blocked.find((b) => b.client.id === getClientId() && b.isBlocked === true)
    return blocked !== undefined
  }
  return false
}

export async function addUserToQueue(userId, data) {
  try {
    console.log(userId)
    console.log(data)
    const user = await api.post('users/portaria/' + userId, data)
    return user
  } catch (e) {
    console.log(e)
    if (e.response) {
      console.log(e.response)
      // O servidor retornou uma resposta, mas com status de erro
      console.error('Erro ao adicionar usuário à fila. Status:', e.response.status)
      console.error('Mensagem do servidor:', e.response.data)
    } else if (e.request) {
      // O pedido foi feito, mas não recebeu resposta
      console.error('Erro ao aguardar resposta do servidor:', e.request)
    } else {
      // Algo aconteceu durante a configuração da solicitação que desencadeou o erro
      console.error('Erro ao configurar a solicitação:', e.message)
    }
    return false
  }
}

// puta gambi, mas é por causa do input-mask supostamente
const convertDate = (inputDate) => {
  if (inputDate) {
    const parts = inputDate.split('/')
    const day = parts[0]
    const month = parts[1]
    const year = parts[2]
    return new Date(`${year}-${month}-${day}`)
  } else {
    return new Date()
  }
}

export async function createNewUser(userInfo, ev) {
  const formData = {
    name: userInfo.name,
    lastname: userInfo.lastname,
    cpf: userInfo.cpf,
    tel: userInfo.tel,
    email: userInfo.email,
    birth: convertDate(userInfo.birth),

    portaria: {
      obs: userInfo.isVip + '\n' + userInfo.obs,
      ts: new Date(),
      admin: getName(),
      evento: ev
    }
  }

  try {
    console.log(formData)
    return await api.post('users', formData)
  } catch (e) {
    alert('Erro ao criar o cadastro:\n\n' + e.response.data.message)
  }
}
