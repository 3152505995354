import React from 'react'

import './Modal.css'
import { BsArrowRepeat } from 'react-icons/bs'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
Modal.propTypes = {
  modal: PropTypes.object
}

export default function Modal({ modal }) {
  function refresh() {
    window.location.reload()
  }
  return (
    <>
      {modal.show ? (
        <div className={`modal-${modal.type}`}>
          <div className="modal-content-ragazzid text-center">
            <h1>{modal.title}!</h1>
            <p>{modal.msg}</p>
            {modal.loading !== true && (
              <>
                {modal.link && !modal.refresh ? (
                  <>
                    <Link id="btnSucesso" to={modal.link} className="m-2 btn btn-warning">
                      Continuar
                    </Link>
                  </>
                ) : (
                  <>
                    <button id="btnSucesso" onClick={refresh} className="m-2 btn btn-warning">
                      Continuar
                    </button>
                  </>
                )}
              </>
            )}

            {modal.loading === true ? (
              <div className="text-center">
                <BsArrowRepeat className="spinning" size={75} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
