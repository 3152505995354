import React from "react";
import { Card } from "react-bootstrap"; // Importe os componentes do Bootstrap

export default function ListBlockedReasons({ user }) {
  return (
    <div>
      <h3>Motivos de Blocks</h3>
      {user.blocked.map((b) => (
        <div key={b._id}>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>
                {b.isBlocked ? "Bloqueado" : "Desbloqueado"}
              </Card.Title>
              <Card.Text>
                <p>{b.reason}</p>
                <p>Até: {new Date(b.until).toLocaleDateString("pt-BR")} </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  );
}
