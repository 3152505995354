import React, { useEffect, useState } from 'react'

import api from '../services/api'

import CaixaAbertos from '../components/CaixaAbertos'
import LoaderButton from '../components/LoaderButton'
import Modal from '../components/Modal'
import { deleteCaixaById } from '../services/caixa'

export default function CaixaAdmin() {
  useEffect(() => {
    getSangria()
    getCaixas()

    // eslint-disable-next-line
  }, [])

  const [caixas, setCaixas] = useState([])
  const [numeroCaixa, setNumeroCaixa] = useState('')
  const [trocoInicial, setTrocoInicial] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({})
  const [totalSangria, setTotalSangria] = useState(0)

  async function getCaixas() {
    const res = await api.get('/caixa')
    setCaixas(res.data)
  }

  async function deleteCaixaByIdAndUpdateState(id) {
    await deleteCaixaById(id)
    await getCaixas()
  }

  async function getSangria() {
    const sangria = await api.get('/caixa/getSangria')
    if (sangria.data.length) {
      setTotalSangria(sangria.data[0].sangria)
    }
  }

  async function handleSubmit() {
    setIsLoading(true)
    try {
      setModal({
        show: true,
        type: 'warning',
        title: 'Tentando criar o caixa',
        msg: 'Aguarde...'
      })

      await api.post('/caixa', { valorInicial: parseInt(trocoInicial), numero: numeroCaixa })
      setIsLoading(false)

      setModal({
        show: true,
        type: 'success',
        title: 'Caixa Criado',
        msg: 'Deu tudo certo!',
        link: '/caixaAdm',
        refresh: true
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.response.data.status),
        link: '/caixaAdm',
        refresh: true
      })
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  function validateForm() {
    return numeroCaixa > 0 && trocoInicial >= 0
  }

  async function limparFilaCaixa() {
    const prompt = window.confirm('Você tem certeza que quer limpar a fila do caixa?')
    if (prompt) {
      setModal({
        show: true,
        type: 'warning',
        title: 'Tentando limpar a fila do caixa',
        msg: 'Aguarde...'
      })
      try {
        await api.delete('users/fila/caixa')
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Deu tudo certo!',
          link: '/'
        })
      } catch (e) {
        setModal({
          show: true,
          type: 'danger',
          title: 'Erro',
          msg: JSON.stringify(e.message),
          link: '/'
        })
      }
    }
  }

  return (
    <div>
      <Modal modal={modal} />
      <h1>Adm Caixa</h1>
      <h3>Novo Caixa</h3>
      <div className="form-group">
        <label htmlFor="iptCaixa">Número do caixa</label>
        <input
          type="number"
          onChange={(e) => setNumeroCaixa(e.target.value)}
          className="form-control"
          id="iptCaixa"
          aria-describedby="iptCaixa"
        />
        <small id="spnCaixa" className="form-text text-muted">
          O número deve ser maior que 0 e não pode ser repetido
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="iptTrocoInicial">Troco Inicial</label>
        <input
          type="number"
          className="form-control"
          onChange={(e) => setTrocoInicial(e.target.value)}
          id="iptTrocoInicial"
          aria-describedby="iptTrocoInicial"
        />
        <small id="spnTrocoInicial" className="form-text text-muted">
          Quanto de dinheiro tem nesse caixa agora?
        </small>
      </div>
      <LoaderButton
        disabled={!validateForm()}
        onClick={handleSubmit}
        isLoading={isLoading}
        type="submit"
        className="btn btn-primary"
      >
        Criar
      </LoaderButton>
      <hr />
      <h3>Total Sangria (mais ou menos 12 horas de retiradas)</h3>
      <h4 style={{ color: 'red' }}>R$ {totalSangria},00</h4>
      <hr />
      <h3>Remover pessoas da fila do caixa</h3>
      <button id="clearQueue" onClick={limparFilaCaixa} className="btn btn-danger">
        Limpar fila do Caixa
      </button>
      <hr />

      <h2>Caixas</h2>

      {caixas.length > 0 ? (
        <CaixaAbertos caixas={caixas} detalhes={false} deleteCaixa={deleteCaixaByIdAndUpdateState} />
      ) : (
        <>Procurando...</>
      )}

      <hr />
      <a href="caixasGeral" alt="Todos os Caixas">
        Todos os Caixas
      </a>
    </div>
  )
}
