import React, { useEffect, useState } from 'react'
import api from '../services/api'
import CaixaAbrir from './CaixaAbrir'
import CaixaOperar from './CaixaOperar'
import Loading from '../components/Loading'
export default function Caixa () {
  const [isLoaded, setIsLoaded] = useState(false)
  const [caixaOpened, setCaixaOpened] = useState(false)
  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad () {
    try {
      const caixa = await api.get('caixa/operador')
      setIsLoaded(true);
      if (caixa.data) {
        setCaixaOpened(true)
      } else {
        setCaixaOpened(false)
      }
    } catch (e) {
      console.log(e)
      if (e.response.status === 401) {
        alert('Faça Login de novo!')
        window.location = '/login'
      }
    }
  }

  if (!isLoaded) {
    return <Loading size={70} />;
  }
  if (caixaOpened) {
    return (
      <CaixaOperar />
    )
  } else {
    return (
      <CaixaAbrir />
    )
  }
}
