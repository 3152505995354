import React, { useEffect, useState } from 'react'
import api from '../services/api'

export default function MinhasAtividades () {
  const [atividades, setAtividades] = useState([])
  const [filtro, setFiltro] = useState([])
  const [carregaTudo, setCarregaTudo] = useState(true)

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line
    }, [])

  function getYesterday () {
    const date = new Date()
    const daysAgo = new Date(date.getTime())
    daysAgo.setDate(date.getDate() - 1)

    return daysAgo.toISOString()
  }
  async function onLoad () {
    const res = await api.get(`feed/getUserActions/${getYesterday()}`)
    setAtividades(res.data)
  }

  function onChange (e) {
    const value = e.target.value
    if (value.length > 0) {
      setCarregaTudo(false)
      setFiltro(atividades.filter(obj => obj.msg.includes(value)))
    } else {
      setCarregaTudo(true)
    }
  }

  return (
    <div>
        <h1>Minhas atividades (Últimas 250)</h1>
        <div>
            <p>
                Filtrar por comanda, ação, telefone, nome
            </p>
            <input onChange={onChange} className="form form-control" />
            <hr />
        </div>
        <div>
            <ul className="list-group">
                { carregaTudo
                  ? (
                    <>
                        { atividades.map((u) => (
                    <li key={u._id} className="list-group-item"> { new Date(u.created).toLocaleString('pt-BR')} | {u.msg}</li>
                        ))}
                    </>
                    )
                  : (
                    <>
                        { filtro.length
                          ? (
                            <>
                                 { filtro.map((u) => (
                                    <li key={u._id} className="list-group-item"> { new Date(u.created).toLocaleString('pt-BR')} | {u.msg}</li>
                                 ))}
                            </>
                            )
                          : (
                            <>
                                Não achei nada :(
                            </>
                            )}
                    </>
                    ) }

            </ul>

        </div>
    </div>
  )
}
