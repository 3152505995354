import React from 'react'
import ListaUserRecargas from './ListaUserRecargas'

export default function RechargeHistory ({ user }) {
  return user.credit_charge && user.credit_charge.length
    ? (
        <div className="container">
          <h3>Histórico de Recargas</h3>
          <ListaUserRecargas user_id={user.id} recargas={user.credit_charge} />
        </div>
      )
    : (
        <div>
          <h3 className="text-center">
            Ainda não recarregou hoje
          </h3>
        </div>
      )
}
