import React, { useEffect, useState } from 'react'

import api from '../../services/api'
import Loading from '../../components/Loading'
import AutoComplete from '../../components/AutoComplete'
import BasicFields from '../../components/Entrance/BasicFields'
import { associateUserWithComandaAndAddCredits, getUserComanda, parseName } from '../../helpers/Users'
import getUserByComanda from '../../services/comanda'
import { createNewUser } from '../../services/users'
import LoaderButton from '../../components/LoaderButton'
import { Modal, Button } from 'react-bootstrap'
import { addUserToEvent, getCurrentEvent } from '../../services/events'

export default function PeopleEvent() {
  const [isFetchingEvents, setIsFecthingEvents] = useState(true)
  const [ev, setEv] = useState({})
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')

  const [isCreating, setIsCreating] = useState(false)
  const [createStatus, setCreateStatus] = useState({})
  const [queryString, setQueryString] = useState('')
  const [userFound, setUserFound] = useState({})

  const [userWithTag, setUserWithTag] = useState(0)
  const [isFilled, setIsFilled] = useState(false)

  const [doc, setDoc] = useState('')
  const [gringo, setGringo] = useState(false)
  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const [bd, setBd] = useState('')

  useEffect(() => {
    getCurrentEvent().then((e) => {
      if (e.length) {
        setEv(e[0])
      }
      setIsFecthingEvents(false)
    })
  }, [])

  useEffect(() => {
    if (userFound.cpf) {
      setTel(userFound.tel)
      setDoc(userFound.cpf)
      setName(parseName(userFound))
      setEmail(userFound.email)
      setBd(new Date(userFound.birth).toLocaleDateString('pt-BR'))
      setUserWithTag(getUserComanda(userFound.comanda))
    }
  }, [userFound])

  function clearEntryInfo() {
    setUserFound({})
    setQueryString('')
    setTel('')
    setDoc('')
    setName('')
    setEmail('')
    setBd('')
    setGringo(false)
  }

  function setDocFromQuery() {
    clearEntryInfo()
    setDoc(queryString)
  }
  function setTelFromQuery() {
    clearEntryInfo()
    setTel(queryString)
  }

  async function entryCreateNewUser() {
    setIsCreating(true)

    if (userFound.tel) {
      console.log('bora testar?')
      const res = await addUserToEvent(userFound.id)
      setCreateStatus({})
      setIsCreating(false)
      setModalTitle('Sucesso')
      setModalText('Operação realizada com sucesso!')
      clearEntryInfo()
      handleShow()
      if (!res) {
        setIsCreating(false)
        setCreateStatus({})
        setModalTitle('Falha')
        setModalText('Falhamos na operação, usuário criado mas n foi adc ao evento, será que tem evento rodando?')
        handleShow()
      }
    } else {
      setCreateStatus({ user: 1, tag: 0 })
      const nomecompleto = name.split(' ')
      const firstName = nomecompleto.shift()
      const lastName = nomecompleto.join(' ')
      const userInfo = {
        name: firstName,
        lastname: lastName,
        cpf: doc,
        tel: tel,
        email: email,
        birth: bd
      }
      try {
        const newUser = await createNewUser(userInfo)

        const res = await addUserToEvent(newUser.data.id)
        if (!res) {
          setIsCreating(false)
          setCreateStatus({})
          setModalTitle('Falha')
          setModalText('Falhamos na operação, usuário criado mas n foi adc ao evento, será que tem evento rodando?')
          handleShow()
        }
        setCreateStatus({ user: 2, tag: 1 })
        setCreateStatus({ user: 2, tag: 2 })
        setCreateStatus({})
        setIsCreating(false)
        setModalTitle('Sucesso')
        setModalText('Operação realizada com sucesso!')
        clearEntryInfo()
        handleShow()
      } catch (e) {
        setIsCreating(false)
        setCreateStatus({})
        setModalTitle('Falha')
        setModalText('Falhamos na operação')
      }
    }

    // setCreateStatus({})
    setIsCreating(false)
  }

  if (isFetchingEvents) {
    return (
      <div>
        <h2>Veficando se há eventos</h2>
        <Loading size={30} />
      </div>
    )
  }
  if (!ev.name) {
    return (
      <div>
        <h2>Desculpe</h2>
        <p>Não há eventos agora, fale com o ADM</p>
      </div>
    )
  }

  return (
    <div className="Comanda">
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Salvar Alterações
          </Button> */}
        </Modal.Footer>
      </Modal>
      <h1 className="my-5"> Adicionar pessoas ao evento</h1>
      <div className="row my-2">
        <div className="col-4">CPF ou Telefone</div>
        <div className="col">
          <AutoComplete
            id="documento"
            query={queryString}
            setQuery={setQueryString}
            setUserFound={setUserFound}
            filled={isFilled}
          />
        </div>
      </div>
      {!userFound.tel ? (
        <>
          <div className="my-3 text-center">Criar novo usuário com</div>
          <div className="row my-3 text-center">
            <div className="col">
              <button className="btn btn-primary" onClick={setDocFromQuery}>
                Doc / CPF
              </button>
            </div>
            <div className="col">
              <button className="btn btn-info" onClick={setTelFromQuery}>
                Telefone
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center my-2">
          <button className="btn btn-warning" onClick={clearEntryInfo}>
            Limpar dados
          </button>
        </div>
      )}
      <div>
        <BasicFields
          setName={setName}
          name={name}
          doc={doc}
          setDoc={setDoc}
          tel={tel}
          setTel={setTel}
          email={email}
          setEmail={setEmail}
          bd={bd}
          setBd={setBd}
          gringo={gringo}
          setGringo={setGringo}
          notPayment={true}
        />
        <div className="text-center my-3">
          {createStatus.user >= 0 && (
            <div className="alert alert-info">
              {createStatus.user == 1 && <p className="alert alert-warning">Criando usuário...</p>}

              {createStatus.user == 2 && <p className="alert alert-primary">Usuário criado, associando comanda...</p>}

              {createStatus.tag == 2 && <p className="alert alert-primary">Tudo certo!</p>}
            </div>
          )}
          <LoaderButton
            id="createNewUser"
            isLoading={isCreating}
            className="btn btn-primary"
            onClick={entryCreateNewUser}
            disabled={doc == '' || name == ''}
          >
            Adicionar ao evento
          </LoaderButton>
        </div>
      </div>
    </div>
  )
}
