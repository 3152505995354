import React, { useState } from 'react'

import { BsSearch } from 'react-icons/bs'

import filterIt from '../views/bar'

// import data from "./data"

import api from '../services/api'
import EstoqueParcial from '../components/EstoqueParcial'
import EstoqueBasico from '../components/EstoqueBasico'

export default function Estoque () {
  const [produto, setProduto] = useState('')
  const [valor, setValor] = useState('')
  const [estoque, setEstoque] = useState(0)
  const [imagem, setImagem] = useState('')
  const [produtos, setProdutos] = useState([])
  const [alertaAlto, setAlertaAlto] = useState(0)
  const [alertaBaixo, setAlertaBaixo] = useState(0)
  const [categoria, setCategoria] = useState('')
  const [destaque, setDestaque] = useState(0)
  const [estoqueIndividual, setEstoqueIndividual] = useState(true)
  const [resultados, setResultados] = useState([])
  const [dependencias, setDependencias] = useState([])
  const [unidade, setUnidade] = useState('')

  async function buscaProdutos () {
    const data = await api.get('/bar/all')

    const busca_conteudo = document.getElementById('bar-search').value.toUpperCase()

    if (busca_conteudo === '') {
      setProdutos(data.data)
    } else {
      const res = filterIt(data.data, busca_conteudo)
      setProdutos(res)
    }
  }

  async function novoProduto (e) {
    e.preventDefault()

    const produtoData = {
      title: produto.toUpperCase(),
      price: valor,
      image: imagem,
      estoque,
      alerta_alto: alertaAlto || 0,
      alerta_baixo: alertaBaixo || 0,
      categoria,
      destaque: destaque || 0,
      unit: unidade,
      dependencies: dependencias
    }

    try {
      await api.post('/bar', produtoData)
      alert('Criado com sucesso')
      setProduto('')
      window.location = '/'
    } catch (e) {
      alert('e falhei')
      console.log(JSON.stringify(e))
    }
  }

  function validateForm () {
    if (estoqueIndividual) {
      return true
    } else {
      return dependencias.length > 0
    }
  }

  return (
    <div>
      <h1>Estoque</h1>
      <hr />
      <h2>Números em Estoque</h2>

      <p>
        Alterar quantidade de{" "}
        <a href="/numerosEstoque" className="btn btn-success">
          produtos
        </a>{" "}
        em estoque
      </p>
      <hr />
      <h2>Novo Produto</h2>
      <p>Preencha todos os campos</p>
      <form>
        <div className="form-group">
          <label htmlFor="produto">Nome do Produto</label>
          <input
            onChange={(e) => setProduto(e.target.value)}
            type="text"
            className="form-control"
            id="produto"
            placeholder="HEINKEN"
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Categoria</label>
          <input
            type="text"
            onChange={(e) => setCategoria(e.target.value)}
            className="form-control"
            id="categoria"
            placeholder=""
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="price">Valor</label>
          <input
            type="num                    ber"
            className="form-control"
            id="price"
            onChange={(e) => setValor(e.target.value)}
            placeholder=""
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Imagem</label>
          <input
            type="text"
            onChange={(e) => setImagem(e.target.value)}
            className="form-control"
            id="image"
            placeholder=""
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Estoque Invidual</label>
          <input
            checked={estoqueIndividual}
            type="checkbox"
            onChange={() => setEstoqueIndividual(!estoqueIndividual)}
            className="form-control"
            id="tipoEstoque"
            placeholder="0"
          ></input>
        </div>

        {estoqueIndividual ? (
          <EstoqueBasico
            estoque={estoque}
            setEstoque={setEstoque}
            alertaAlto={alertaAlto}
            setAlertaAlto={setAlertaAlto}
            setAlertaBaixo={setAlertaBaixo}
            alertaBaixo={alertaBaixo}
            unidade={unidade}
            setUnidade={setUnidade}
          />
        ) : (
          <EstoqueParcial
            resultados={resultados}
            dependencias={dependencias}
            setResultados={setResultados}
            setDependencias={setDependencias}
          />
        )}

        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Destaque</label>
          <input
            type="number"
            onChange={(e) => setDestaque(e.target.value)}
            className="form-control"
            id="destaque"
            placeholder="0"
          ></input>
        </div>

        <button
          disabled={!validateForm()}
          type="submit"
          onClick={novoProduto}
          className="btn btn-primary"
        >
          Enviar
        </button>
      </form>
      <hr></hr>
      <h2>Pesquisar produtos</h2>
      <div className="my-3 input-group">
        <div id="search-autocomplete" className="form-outline">
          <input
            onChange={buscaProdutos}
            type="search"
            id="bar-search"
            className="form-control"
          ></input>
        </div>
        <button
          onClick={buscaProdutos}
          type="button"
          className="btn btn-primary"
        >
          <BsSearch />
        </button>
      </div>
      <div className="container text-center">
        <div className="row" id="busca-alvo">
          {produtos.map((p) => (
            <div className="col box" key={p.id}>
              <p className="text-center"> {p.title}</p>
              <a href={"estoque/" + p.id}>
                <img
                  className="text-center"
                  src={p.image}
                  alt={p.title}
                  height="100"
                  width="100"
                ></img>{" "}
              </a>
              <p className="text-center"> R$ {p.price.toFixed(2)}</p>
              <a href={"estoque/" + p.id}>Editar</a>
              <hr></hr>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
