import React from "react";
import { Modal, Button } from "react-bootstrap";
import Cesta from "../../containers/Cesta";

function ProductModal({ show, handleClose, user, setUser }) {
  return (
    <Modal show={show} onHide={handleClose} closeLabel="Fechar">
      <Modal.Header closeButton>
        <Modal.Title>Lista de Produtos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Cesta user={user} setUser={setUser} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductModal;
