import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'

export default function CriarUsuarioAssociarComanda () {
  function isValidEmail (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  function isValidCPF (cpf) {
    if (typeof cpf !== 'string') return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
      !cpf ||
            cpf.length !== 11 ||
            cpf === '00000000000' ||
            cpf === '11111111111' ||
            cpf === '22222222222' ||
            cpf === '33333333333' ||
            cpf === '44444444444' ||
            cpf === '55555555555' ||
            cpf === '66666666666' ||
            cpf === '77777777777' ||
            cpf === '88888888888' ||
            cpf === '99999999999'
    ) {
      return false
    }
    let soma = 0
    let resto
    for (let i = 1; i <= 9; i++) { soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i) }
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (let i = 1; i <= 10; i++) { soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i) }
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(10, 11))) return false
    return true
  }

  function makeId (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
     charactersLength))
    }
    return result
  }

  useEffect(() => {
    onLoad()
  }, [])

  const params = useParams()
  async function onLoad () {
    let email = ''
    let cpf = '5100' + Math.floor(Math.random() * 1000000000)
    const tel = '5100' + Math.floor(Math.random() * 1000000000)
    if (isValidEmail(params.campo)) {
      email = params.campo
    } else if (isValidCPF(params.campo)) {
      cpf = params.campo
    } else {
      let tel = params.campo.replace(/[^\w\s]/gi, '')
      if (/^\d+$/.test(tel)) {
        tel = params.campo
      } else {
        alert('Email / CPF ou Telefone Incorretos, por favor reveja o campo')
        history.push('/comanda')
      }
    }
    const name = makeId(12)
    if (email === '') {
      email = name + Math.floor(Math.random() * 1000000) + '@kitnet.fake'
    }

    const formData = {
      name,
      lastname: name,
      email,
      rg: '111111111111',
      cpf,
      birth: new Date(),
      tel,
      pass: new Date()
    }
    try {
      const res = await api.post('users', formData)
      try {
        await api.post(
          'users/' + res.data.id + '/comanda',
          { comanda: params.comanda }
        )
        alert('Sucesso')
        history.push('/comanda')
      } catch (e) {
        alert('Falha\n' + e.response.data.message)
        history.push('/comanda')
      }
    } catch (e) {
      alert('Erro ao criar o cadastro:\n\n' + e.response.data.message)
      history.push('/comanda')
    }
  }

  return (
      <div>
          <h1>Criar user e Associar a comanda</h1>
          <div id="target">

          </div>
      </div>
  )
}
