import React from 'react'
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai'
import api from '../services/api'
import PropTypes from 'prop-types'

EstoqueParcial.propTypes = {
  resultados: PropTypes.string,
  dependencias: PropTypes.string,
  setResultados: PropTypes.string,
  setDependencias: PropTypes.string
}

export default function EstoqueParcial ({
  resultados, dependencias, setResultados, setDependencias
}) {
  async function busca (search) {
    if (search.length > 1) {
      const res = await api.get(`/bar/search/${String(search)}`)
      setResultados(res.data)
    }
  }

  async function adc (item) {
    const exist = dependencias.find((i) => String(i.id) === String(item.id))
    if (exist) {
      alert('Item já adicionado')
    } else {
      const inputQtdy = document.getElementById(item.id)
      const qtd = inputQtdy.value
      dependencias.push({
        ...item,
        qtd
      })
      setDependencias(dependencias)
      setResultados([])
    }
  }
  function remove (item) {
    const d = dependencias.filter((i) => String(i.id) !== String(item.id))
    setDependencias(d)
  }

  return (
    <div>
      <h4>Adicione dependencias</h4>
      <div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Procurar por Items</label>
          <input type="text" onChange={(e) => busca(e.target.value)} className="form-control" id="busca" placeholder="" />
        </div>
        <div className="container">
          { resultados.map((r) => (
            <div key={r.id} className="row">
              <div className="col-3">
                <input id={r.id} className="form form-control" type="number" />
              </div>
              <div className="col-4">
                {r.title}
                {' '}
                (
                {r.unit}
                )
              </div>
              <div className="col-2">
                <AiFillPlusCircle onClick={() => { adc(r) }} size={20} />
              </div>
            </div>
          ))}

        </div>
      </div>
      <hr />
      Dependencias:
      <div className="container">
        { dependencias.map((d) => (
          <div key={d.id} className="row">
            <div className="col-4">
              {d.qtd}
              {' '}
              {d.unit}
            </div>
            <div className="col-4">{d.title}</div>
            <div className="col-2">
              <AiFillMinusCircle onClick={() => { remove(d) }} size={20} />
            </div>
          </div>
        ))}
      </div>
      <hr />
    </div>
  )
}
